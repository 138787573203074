var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messageDialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "roomManagementDialog",
          attrs: {
            title: "消息提示",
            top: "3%",
            visible: _vm.show,
            width: "80%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "title2 flexItem",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("img", {
                staticClass: "titleImg",
                attrs: {
                  src: require("../../../../../assets/benchCss/img/icon-list.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "ml" }, [
                _vm._v("\n    工作效率\n   "),
              ]),
            ]
          ),
          _c(
            "el-form",
            {
              ref: "searchForm",
              attrs: { "label-width": "70px", model: _vm.searchForm },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { gutter: 10 },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "开始时间", prop: "startDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              "picker-options": _vm.startDateDisabled,
                              "default-time": "00:00:00",
                              type: "datetime",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.searchForm.startDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "startDate", $$v)
                              },
                              expression: "searchForm.startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "结束时间", prop: "endDate" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "picker-options": _vm.endDateDisabled,
                              "value-format": "yyyy-MM-dd HH:mm:ss",
                              type: "datetime",
                              "default-time": "23:59:59",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.searchForm.endDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "endDate", $$v)
                              },
                              expression: "searchForm.endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content reset-button" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleSearch },
                          },
                          [_vm._v("查询")]
                        ),
                        _c(
                          "el-button",
                          { attrs: { size: "mini" }, on: { click: _vm.reset } },
                          [_vm._v("重置")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 10 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", "label-width": "0" } },
                        [
                          _c("span", [_vm._v("总做房次数")]),
                          _c("span", { staticClass: "theNum" }, [
                            _vm._v(_vm._s(_vm.topData.cleanRoomCount)),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "20px" } },
                            [_vm._v("次")]
                          ),
                          _c("span", [_vm._v("总做房率")]),
                          _c("span", { staticClass: "theNum" }, [
                            _vm._v(_vm._s(_vm.topData.cleanRoomRate)),
                          ]),
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "20px" } },
                            [_vm._v("%")]
                          ),
                          _c("span", [_vm._v("总做房平均时长")]),
                          _c("span", { staticClass: "theNum" }, [
                            _vm._v(_vm._s(_vm.topData.cleanRoomLongTime)),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "managerTablist",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.tablist,
                    id: "tab",
                    height: 500,
                    stripe: "",
                    "header-row-class-name": "tabHeader",
                  },
                },
                _vm._l(_vm.execlTab, function (item, i) {
                  return _c("el-table-column", {
                    key: i,
                    attrs: {
                      width: item.width,
                      prop: item.value,
                      align: item.type == "operation" ? "center" : "",
                      label: item.label,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              item.type == "operation"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.assignTask(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("工作轨迹")]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              item.solt_TEXT
                                ? _c("div", [
                                    _vm._v(
                                      "\n       " +
                                        _vm._s(
                                          _vm.showLabelText(
                                            scope.row[item.value],
                                            _vm.typeLists[item.solt_TEXT]
                                          )
                                        ) +
                                        "\n      "
                                    ),
                                  ])
                                : item.value == "opsAverageTime"
                                ? _c("div", [
                                    _vm._v(
                                      "\n       " +
                                        _vm._s(
                                          _vm.showLongTimes(
                                            scope.row.opsAverageTime
                                          )
                                        ) +
                                        "\n      "
                                    ),
                                  ])
                                : item.value == "endTime"
                                ? _c("div", [
                                    _vm._v(
                                      "\n       " +
                                        _vm._s(_vm.showDay(scope.row.endTime)) +
                                        "\n      "
                                    ),
                                  ])
                                : item.value == "opsPercent"
                                ? _c("div", [
                                    _vm._v(
                                      "\n       " +
                                        _vm._s(
                                          (scope.row.opsPercent
                                            ? scope.row.opsPercent * 100
                                            : 0) + "%"
                                        ) +
                                        "\n      "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      "\n       " +
                                        _vm._s(scope.row[item.value]) +
                                        "\n      "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "footerPage",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.pageNum,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.pageSize,
                  layout: "prev, pager, next, sizes, total, jumper",
                  total: _vm.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
              _c(
                "div",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.exportExcel(0)
                        },
                      },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("trackDetails", { ref: "trackDetails" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }