<template>
 <div class="demo theBar borderBar">
  <div class="title flexSb">
   <div>
    能耗信息
   </div>
   <div class="openIcon flexCenter">
    <!-- <el-dropdown class="user-name" trigger="click" @command="handleCommand">
     <span class="el-dropdown-link">
      {{type==1?'今日能耗':"本月能耗"}}
      <i class="el-icon-caret-bottom"></i>
     </span>
     <el-dropdown-menu slot="dropdown" style="padding:0">
      <el-dropdown-item divided command="1" style="border:0;margin-top:0">今日能耗</el-dropdown-item>
      <el-dropdown-item divided command="2">本月能耗</el-dropdown-item>
     </el-dropdown-menu>
    </el-dropdown> -->
    <i class="el-icon-search iconColor ml" @click="openDetail"></i>
   </div>

  </div>
  <div class="tabTop flexSb">

   <div class=" tab1">
    <div class="iconTab flexCenter">
     <img src="../../../../assets/benchCss/img/icon-znh.png" alt="">
    </div>
    <div class="tabNum">
     <CountTo :start-val="0" :end-val="total" :duration="3000"></CountTo>
    </div>
    <div class="content2">总能耗</div>

   </div>
   <div class=" tab1">
    <div class="iconTab flexCenter">
     <img src="../../../../assets/benchCss/img/icon-kfnh.png" alt="">
    </div>
    <div class="tabNum">
     <CountTo :start-val="0" :end-val="severNum" :duration="3000"></CountTo>
     <!-- {{severNum}} -->
    </div>
    <div class="content2">内部消耗</div>

   </div>
   <div class=" tab1">
    <div class="iconTab flexCenter">
     <img src="../../../../assets/benchCss/img/icon-nbnh.png" alt="">
    </div>
    <!-- :decimals="2" -->
    <div class="tabNum">
     <CountTo :start-val="0" :end-val="kf"  :duration="3000"></CountTo>
     <!-- {{kf}} -->
    </div>
    <div class="content2">客房消耗</div>

   </div>

  </div>
  <div class="workList scroll2">
   <el-table :data="tablist" :show-header="false" align="center" class="managerTablist" :stripe="false">
    <el-table-column label="姓名" align="center">
     <template slot-scope="scope">
      <div class="roomNumColor">
       {{scope.row.roomNumber}}
      </div>
     </template>
    </el-table-column>
    <el-table-column prop="name" label="姓名" align="center">
    </el-table-column>
    <el-table-column prop="date" label="日期" align="center" show-overflow-tooltip>
    </el-table-column>

   </el-table>

  </div>
 </div>
</template>
<script>
import CountTo from "vue-count-to";
export default {
 components: {
  CountTo,
 },
 data() {
  return {
   type: 1,

   tablist: [
    {
     roomNumber: 8310,
     date: "3.5度",
     name: "9小时",
    },
    {
     roomNumber: 8506,
     date: "3.2度",
     name: "8小时",
    },
    {
     roomNumber: 8808,
     date: "3度",
     name: "12小时",
    },
    {
     roomNumber: 8508,
     date: "2度",
     name: "6小时",
    },
   ],
  };
 },

 computed: {
  kf() {
   return Math.floor(Math.random() * 5 + 80);
  },
  severNum() {
   return Math.floor(Math.random() * 5 + 30);
  },
  total() {
   return this.kf + this.severNum;
  },
 },
 methods: {
  openDetail() {
   this.$message.warning("能耗详情暂未开放");
  },
 },
};
</script>
<style lang="scss" scoped>
@import url("../../../../assets/benchCss/base.scss");
.title {
 border: none;
}
.demo {
 width: 100%;
 height: 100%;
 .workList {
  width: 100%;
  height: calc(100% - 16vh);
  overflow-y: auto;
 }
}
.tabTop {
 //  padding: 0.5vh 1vw;
 height: 10vh;
 //  line-height: 5vh;
}
.tab1 {
 text-align: center;
 position: relative;
 flex: 1;
 .iconTab {
  height: 3vh;
  i {
   font-size: 3vh;
   color: chocolate;
  }
 }
 .tabNum {
  // height: 4vh;
  // padding-top: 1vh;
  // line-height: 3vh;
  line-height: 3.8vh;
  font-weight: bold;
  color: #1c1f3e;
  // font-family: "方正粗黑宋简体";
  font-family: "Arial Black";
  font-size: 3.5vh;
 }
}
.tab1 + .tab1 {
 &::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 50%;
  width: 2px;
  border-radius: 2px;
  background-color: #8796a9;
 }
}
</style>