<template>
 <div class="demo theBar borderBar">
  <div class="workTitle flexSb">
   <div>

    <div class="flexItem">
     <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
     <span class="ml">
      工作效率
     </span>
    </div>
   </div>
   <div class="openIcon flexCenter" @click="openView">
    <i class="el-icon-search iconColor"></i>
   </div>
  </div>
  <div class="workTitle " style="padding-top:0">
   <div class="content2" style="text-align:left">
    员工近期工作效率
   </div>
  </div>
  <div class="workers flexItem">
   <el-carousel ref="workSwiper" :interval="5000" arrow="never" indicator-position="none" :autoplay="false" :initialIndex="activeIndex" height="5vh" class="flex1">
    <el-carousel-item v-for="(itemIndex,i) in menuPages" :key="i">
     <div class="flexItem h100 overBar scroll">
      <div v-for="(item,index) in pageSize" :key="index" class=" h100">
       <div class="  workerItem h100" v-if="((itemIndex-1)*pageSize+index)<items.length" :class="{activeWorker:(itemIndex - 1) * pageSize + index < items.length&&items[(itemIndex - 1) * pageSize + index].employeeId==activeId}" @click="changeWoeker(items[(itemIndex - 1) * pageSize + index])">
        <div class="workerSize workerName" v-if="(itemIndex - 1) * pageSize + index < items.length" :style="'background-color: '+colors[index]">
         {{items[(itemIndex - 1) * pageSize + index].employeeName|theName}}
        </div>
       
       </div>
      </div>

     </div>
    </el-carousel-item>
   </el-carousel>
   <div @click="setActiveItem" class="toNext" v-if="menuPages">
    <i class="el-icon-arrow-right"></i>
   </div>
  </div>
  <div class="workList">

   <div class="title flexSb">
    <div>
     {{acTiveData.employeeName}}工作轨迹
    </div>
    <div class="rightText flexCenter">
     <div>平均效率</div>
     <span class="timeShow"><i class="el-icon-time timeIcon"></i> {{avg|longTime2}} </span>
    </div>
   </div>
   <div class=" tabRowsBar  scroll2">
    <el-table :data="tablist" :show-header="false" class="managerTablist pd0tab">
     <el-table-column prop="roomNumber" label="房间号" align="center" width="80">
      <template slot-scope="scope">
       <div class="roomNumColor">
        {{scope.row.roomNumber}}
       </div>
      </template>
     </el-table-column>

     <!-- <el-table-column align="left" label="时间">
      <template slot-scope="scope">
       <div class="timesRange">
        {{scope.row.startTime|startAndEnd(scope.row.endTime)}}
       </div>
      </template>
     </el-table-column> -->
     <el-table-column align="right" label="时间" >
      <template slot-scope="scope">
       <!-- <div class="timeBar"> -->
        {{scope.row.startTime|returnTime}}
       <!-- </div> -->
      </template>
     </el-table-column>
     <el-table-column align="center" label="至" width="18" >
      <template slot-scope="scope">
       至
      </template>
     </el-table-column>
     <el-table-column align="left" label="时间" >
      <template slot-scope="scope">
       <!-- <div class="timeBar"> -->
        {{scope.row.endTime|returnTime}}
       <!-- </div> -->
      </template>
     </el-table-column>
     <el-table-column prop="date" label="时长" align="center" show-overflow-tooltip  width="100">
      <template slot-scope="scope">
       <!-- <div> -->
        {{scope.row.startTime|longTime(scope.row.endTime)}}
       <!-- </div> -->
      </template>
     </el-table-column>

    </el-table>

   </div>
  </div>
  <workerDetail ref="workerDetail"></workerDetail>
 </div>
</template>
<script>
import workerDetail from "./workerDetail.vue";
import { gettrackList, getWorkerList } from "@/api";
import {
   getLongDateTime,
   secondGetLongTime
} from "@/api/common.js";
let reg = /^[\u4e00-\u9fa5]{3,6}$/;
export default {
 filters: {
  theName: function (value) {
   if (!value) return "";
   value = value.toString();
   if (value.length <= 2) return value;
   else if (reg.test(value))
    return value.substring(value.length - 2, value.length);
   return value.substring(0, 3);
  },
  startAndEnd(start, end) {
   return `${start?start.substring(0,start.length-3):'~'} 至 ${end? end.substring(0,end.length-3):'~'}`
  },
  returnTime(t){
     if(t) return `${t.substring(0,t.length-3)}`
  },
  longTime(start, end) {
   return getLongDateTime(start, end)
  },
  longTime2(val) {
     if(val) return secondGetLongTime(val*60)
   return '0分钟'
  },
 },
 components: {
  workerDetail,
 },

 data() {
  return {
   avg: 0,
   activeIndex: 0,
   pageSize: 5,
   activeId: null,
   acTiveData: {},
   items: [],
   colors: ["#52D5BA", "#FFAF2A", "#0890FE", "#FF4267", "#6d60ed"],
   tablist: [],
  };
 },

 computed: {
  menuPages() {
   if (this.items.length) return Math.ceil(this.items.length / this.pageSize);
   return 0;
  },
  hotelData() {
   return this.$store.state.hotelData;
  },
 },
 created() {
  //   this.activeId = this.items[0].id;
  this.getTopWorks();
 },

 methods: {
  gettrackList() {
   let cardSn = this.acTiveData.cardSn,
    hotelId = this.hotelData.id;
   gettrackList({ cardSn, hotelId }).then((res) => {
      console.log(res,'---');
    this.tablist = res.data.data.list;
    console.log(this.tablist);
    this.avg = res.data.data.avg;
   });
  },
  getTopWorks() {
   let data = {
    hotelId: this.hotelData.id,
    orderType: 3,
    source:'workbench'
   };
   getWorkerList(data).then((res) => {
   //  console.log(res);
   if(res.data.code=='000000'){
      this.items = res.data.data;
      if(this.items.length){
         this.activeId = this.items[0].employeeId;
         this.acTiveData = this.items[0];
         this.gettrackList();
      }
   }
   });
  },
  changeWoeker(item) {
     if( this.activeId != item.employeeId){
        this.activeId = item.employeeId;
        this.acTiveData = item;
        this.gettrackList();
     }
  },
  openView() {
   this.$refs.workerDetail.openDialog();
  },
  showName(itemIndex, index) {
   if ((itemIndex - 1) * this.pageSize + index < this.items.length) {
    let name = this.items[(itemIndex - 1) * this.pageSize + index].employeeName;
    return name;
   }
  },
  setActiveItem() {
   this.activeIndex++;
   if (this.menuPages <= this.activeIndex) this.activeIndex = 0;
   this.$refs.workSwiper.setActiveItem(this.activeIndex);
  },
 },
};
</script>
<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");
.timeBar{
   white-space: nowrap;
}
.h100 {
 height: 100%;
}
.roomNumColor {
 font-size: 1.4vh !important;
}
.timeIcon {
 font-size: 0.6vh;
}
.title {
 border: none;
}
.demo {
 width: 100%;
 height: 100%;
 .workList {
  width: 100%;
  height: calc(100% - 13vh);
 }
 .tabRowsBar {
  width: 100%;
  height: calc(100% - 7.8vh);
  overflow-y: auto;
 }
}
.workTitle {
 background-color: #fff;
 //  height: 5.5vh;
 height: 3vh;
 font-size: 1.6vh;
 font-weight: bold;
 padding: 0.5vh 1vw 0;
}
.workers {
 padding: 1.5vh 1vw;
 line-height: 5vh;
}
.workerItem.activeWorker .workerName {
 //  border: 1.5px solid #12a5ff;
 background: #623ce7 !important;
 //  color: #623ce7;
 color: #fff;
}
.workerName {
 //  border: 1.5px solid #ff7824;
 background: #fff;
 color: #fff;
 font-size: 1.3vh;
 //  color: #ff7824;
 //  font-weight: bold;
 //  font-size: 2vh;
 transition: all 0.5s;
 text-align: center;
 //  font-size: 12px;
}
.text3Bar {
 font-size: 2vh;
}
.smallText {
 font-size: 1vh;
}
.workerSize {
//  width: 3.8vh;
padding: 0 6px;
white-space: nowrap;
 height: 3.8vh;
 line-height: 3.8vh;
 //  border-radius: 50%;
 border-radius: 8px;
 //  padding: ;
 overflow: hidden;
 img {
  width: 100%;
  height: 100%;
 }
}
.overBar {
 overflow-x: auto;
}
.workerItem {
 margin-right: 1.5vw;
 cursor: pointer;
 position: relative;
}
.workerItem::before {
 content: "";
 //  background-color: #623ce7;
 position: absolute;
 bottom: 0;
 left: 50%;
 transform: translateX(-50%);
 width: 0.01vw;
 height: 0.4vh;
 border-radius: 0.2vh;
 transition: all 0.5s;
}
.workerItem.activeWorker::before {
 width: 1.3vw;
 background-color: #623ce7;
 transition: all 0.5s;
}
.toNext {
 width: 1vw;
 text-align: center;
 cursor: pointer;
 i {
  font-size: 2vh;
  font-weight: bold !important;
 }
}
.rightText {
 color: #ff7824;
 font-size: 0.6vh;
 line-height: 2.5vh;
}
.timeShow {
 padding: 0.05vh 0.5vw;
 margin-left: 0.3vw;
 background-color: #ff0000;
 color: #fff;
 font-size: 1.2vh;
 line-height: 2.5vh;
 border-radius: 5px;
}
.content2 {
 font-weight: normal;
}
</style>