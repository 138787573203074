<template>
 <div class="messageDialog">
  <!-- 客房管理 -->
  <el-dialog title="消息提示" top="3%" :visible.sync="show" width="60%" class="roomManagementDialog" :close-on-click-modal="false">
   <div slot="title" class="title2 flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <!-- <i class="el-icon-tickets iconColor"></i> -->
    <span class="ml">
     报警列表
    </span>
   </div>
   <el-row :gutter="20" class="topTab">
    <el-col :span="4">
     <div class="Statistics" :class="{active:!text}" @click="changeText()">
      <span class="name">全部</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='SOS'}" @click="changeText('SOS')">
      <span class="name">SOS</span>
      <span class="total">{{ roomNum.sosTotal }}</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='门牌离线'}" @click="changeText('门牌离线')">
      <span class="name">门牌离线</span>
      <span class="total">{{ roomNum.faceOnline }}</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='清理操作'}" @click="changeText('清理操作')">
      <span class="name">清理操作</span>
      <span class="total">{{ roomNum.qingliTotal }}</span>
     </div>
    </el-col>
    <el-col :span="5">
     <div class="Statistics" :class="{active:text=='门磁报警'}" @click="changeText('门磁报警')">
      <span class="name">门磁报警</span>
      <span class="total">{{ roomNum.menciTotal }}</span>
     </div>
    </el-col>
   </el-row>
   <div>
    <el-table ref="multipleTable" :data="list" tooltip-effect="dark" height="500" style="width: 100%" class="managerTablist" stripe header-row-class-name="tabHeader">
     <el-table-column prop="buildingName" label="建筑"></el-table-column>
     <el-table-column prop="floorName" label="楼层"></el-table-column>
     <el-table-column prop="roomNumber" label="房间号"></el-table-column>
     <el-table-column prop="status" label="消息类型">
      <template slot-scope="scope">
       <div>
        <span class="roomStatusName" :class="{
          isSOS: scope.row.status == 'SOS',
          isQingLi: scope.row.status == '清理操作',
          isMenCi: scope.row.status == '门磁报警',
          isOff:scope.row.status == '门牌离线'
                          }">{{ scope.row.status }}</span>
       </div>
      </template>
     </el-table-column>
    </el-table>
   </div>
   <div slot="footer" class="dialog-footer">
    <div>

     <el-button size="small"  @click="exportExcel(0)" :loading="downLoading">导出</el-button>
     <el-button size="small" @click="show =false">取消</el-button>

    </div>
   </div>
  </el-dialog>

 </div>
</template>
<script>
import { export_json_to_excel } from "@/vendor/Export2Excel.js";
// import {labelShowDate} from "@/api/common.js"
export default {
 props: {
  tablist: {
   default: [],
   type: Array,
  },
  execlTab: {
   default: [],
   type: Array,
  },
 },
 data() {
  return {
   show: false,
   text: "",
   downLoading: false,
  };
 },
 computed: {
  list() {
   if (this.text) {
    return this.tablist.filter((item) => {
     return item.status == this.text;
    });
   } else {
    return this.tablist.filter((item) => {
     return item.status;
    });
   }
  },
  roomNum() {
   let obj = {
    sosTotal: 0,
    menciTotal: 0,
    qingliTotal: 0,
    faceOnline: 0,
   };
   this.tablist.forEach((item) => {
    if (item.status == "SOS") {
     obj.sosTotal++;
    } else if (item.status == "门牌离线") {
     obj.faceOnline++;
    } else if (item.status == "清理操作") {
     obj.qingliTotal++;
    } else if (item.status == "门磁报警") {
     obj.menciTotal++;
    }
   });
   return obj;
  },
 },

 methods: {
  // 导出
  exportExcel(i) {
   console.log(this.execlTab);
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$message.warning("未加载到数据");
     this.downLoading = false;
     return;
    }
    let tHeader = [];
    let filterVal = [];
    this.execlTab.forEach((v) => {
     tHeader.push(v.label);
     filterVal.push(v.value);
    });
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
  //  let d2=labelShowDate(new Date())
  //  console.log(d2);
    setTimeout(() => {
     export_json_to_excel(tHeader, data, `报警列表`);
     this.downLoading = false;
    }, 500);
   }
  },
  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    arr.push(obj);
   });
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },

  changeText(text = "") {
   this.text = text;
  },
  openDialog() {
   this.show = true;
  },
 },
};
</script>


<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");

.topTab {
 //  border-bottom: 3px solid rgb(80, 80, 80);
 padding-bottom: 20px;
 //  margin-bottom: 10px;
}
</style>