var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "demo theBar borderBar" },
    [
      _c("div", { staticClass: "workTitle flexSb" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "openIcon flexCenter", on: { click: _vm.openView } },
          [_c("i", { staticClass: "el-icon-search iconColor" })]
        ),
      ]),
      _vm._m(1),
      _c(
        "div",
        { staticClass: "workers flexItem" },
        [
          _c(
            "el-carousel",
            {
              ref: "workSwiper",
              staticClass: "flex1",
              attrs: {
                interval: 5000,
                arrow: "never",
                "indicator-position": "none",
                autoplay: false,
                initialIndex: _vm.activeIndex,
                height: "5vh",
              },
            },
            _vm._l(_vm.menuPages, function (itemIndex, i) {
              return _c("el-carousel-item", { key: i }, [
                _c(
                  "div",
                  { staticClass: "flexItem h100 overBar scroll" },
                  _vm._l(_vm.pageSize, function (item, index) {
                    return _c("div", { key: index, staticClass: " h100" }, [
                      (itemIndex - 1) * _vm.pageSize + index < _vm.items.length
                        ? _c(
                            "div",
                            {
                              staticClass: "  workerItem h100",
                              class: {
                                activeWorker:
                                  (itemIndex - 1) * _vm.pageSize + index <
                                    _vm.items.length &&
                                  _vm.items[
                                    (itemIndex - 1) * _vm.pageSize + index
                                  ].employeeId == _vm.activeId,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.changeWoeker(
                                    _vm.items[
                                      (itemIndex - 1) * _vm.pageSize + index
                                    ]
                                  )
                                },
                              },
                            },
                            [
                              (itemIndex - 1) * _vm.pageSize + index <
                              _vm.items.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "workerSize workerName",
                                      style:
                                        "background-color: " +
                                        _vm.colors[index],
                                    },
                                    [
                                      _vm._v(
                                        "\n        " +
                                          _vm._s(
                                            _vm._f("theName")(
                                              _vm.items[
                                                (itemIndex - 1) * _vm.pageSize +
                                                  index
                                              ].employeeName
                                            )
                                          ) +
                                          "\n       "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ])
            }),
            1
          ),
          _vm.menuPages
            ? _c(
                "div",
                { staticClass: "toNext", on: { click: _vm.setActiveItem } },
                [_c("i", { staticClass: "el-icon-arrow-right" })]
              )
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "workList" }, [
        _c("div", { staticClass: "title flexSb" }, [
          _c("div", [
            _vm._v(
              "\n    " + _vm._s(_vm.acTiveData.employeeName) + "工作轨迹\n   "
            ),
          ]),
          _c("div", { staticClass: "rightText flexCenter" }, [
            _c("div", [_vm._v("平均效率")]),
            _c("span", { staticClass: "timeShow" }, [
              _c("i", { staticClass: "el-icon-time timeIcon" }),
              _vm._v(" " + _vm._s(_vm._f("longTime2")(_vm.avg)) + " "),
            ]),
          ]),
        ]),
        _c(
          "div",
          { staticClass: " tabRowsBar  scroll2" },
          [
            _c(
              "el-table",
              {
                staticClass: "managerTablist pd0tab",
                attrs: { data: _vm.tablist, "show-header": false },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "roomNumber",
                    label: "房间号",
                    align: "center",
                    width: "80",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("div", { staticClass: "roomNumColor" }, [
                            _vm._v(
                              "\n       " +
                                _vm._s(scope.row.roomNumber) +
                                "\n      "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "right", label: "时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n       " +
                              _vm._s(
                                _vm._f("returnTime")(scope.row.startTime)
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "center", label: "至", width: "18" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [_vm._v("\n      至\n     ")]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: { align: "left", label: "时间" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n       " +
                              _vm._s(_vm._f("returnTime")(scope.row.endTime)) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "date",
                    label: "时长",
                    align: "center",
                    "show-overflow-tooltip": "",
                    width: "100",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _vm._v(
                            "\n       " +
                              _vm._s(
                                _vm._f("longTime")(
                                  scope.row.startTime,
                                  scope.row.endTime
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("workerDetail", { ref: "workerDetail" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "flexItem" }, [
        _c("img", {
          staticClass: "titleImg",
          attrs: {
            src: require("../../../../../assets/benchCss/img/icon-list.png"),
            alt: "",
          },
        }),
        _c("span", { staticClass: "ml" }, [_vm._v("\n     工作效率\n    ")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "workTitle ", staticStyle: { "padding-top": "0" } },
      [
        _c(
          "div",
          { staticClass: "content2", staticStyle: { "text-align": "left" } },
          [_vm._v("\n   员工近期工作效率\n  ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }