<template>
 <div class="messageDialog">
  <!-- 客房管理 -->
  <el-dialog title="消息提示" top="3%" :visible.sync="show" width="80%" class="roomManagementDialog" :close-on-click-modal="false">
   <div slot="title" class="title2 flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <!-- <i class="el-icon-tickets iconColor"></i> -->
    <span class="ml">
     异常记录
    </span>
   </div>
   <el-form label-width="70px" ref="searchForm" :model="searchForm">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="4">
      <el-form-item label="房间号" label-width="60px" prop="roomNumber">
       <el-input placeholder="请输入房间号" v-model.trim="searchForm.roomNumber" clearable class="my-input"></el-input>

      </el-form-item>
     </el-col>
     <!-- <el-col :span="3">
      <el-form-item label="UUID" label-width="50px" prop="mainboardId">
       <el-input placeholder="请输入UUID" v-model.trim="searchForm.mainboardId" clearable class="my-input"></el-input>

      </el-form-item>
     </el-col> -->
     <el-col :span="4">
      <el-form-item label="主机类型" prop="mainframeType">
       <el-select v-model="searchForm.mainframeType" clearable placeholder="请选择">
        <el-option v-for="(item,index) in hostType" :key="index" :label="item" :value="item"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="异常类型" prop="warningType">
       <el-select v-model="searchForm.warningType" clearable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.abnormals" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="3">
      <el-form-item label="异常状态" prop="state">
       <el-select v-model="searchForm.state" clearable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.abnormalStatus" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="触发时间" prop="times">
       <el-date-picker v-model="searchForm.times" type="daterange" :default-time="defaultTime" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" style="width:100%"></el-date-picker>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>

      </div>
     </el-col>
    </el-row>

   </el-form>
   <div>

    <el-table :data="tablist" v-loading="loading" id="tab" :height="500" style="width: 100%" class="managerTablist" stripe header-row-class-name="tabHeader" @selection-change="handleSelectionChange">
     <el-table-column type="selection" width="50" align="center">
     </el-table-column>
     <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :align="item.type=='operation'?'center':''" :label="item.label">
      <template slot-scope="scope">
       <div v-if="item.type=='operation'&&scope.row.opsStatus==0">
        <el-button type="primary" size="mini" @click="assignTask(scope.row)">分派</el-button>
       </div>
       <div v-if="item.solt_TEXT">
        {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
       </div>
       <div v-else-if="item.value=='longTimes'">
        {{showLongTimes(scope.row.startTime,scope.row.endTime)}}
       </div>
       <div v-else>
        {{scope.row[item.value]}}
       </div>
      </template>
     </el-table-column>
    </el-table>
   </div>
   <div slot="footer" class="footerPage">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
    <div>
     <el-button size="small" @click="exportExcel(0)">导出</el-button>
     <el-button size="small" @click="show=false">取消</el-button>
     <el-button size="small" type="primary" @click="assignTaskAll">分派</el-button>

    </div>
   </div>
  </el-dialog>
  <assignTaskView ref="assignTaskView" />

 </div>
</template>
<script>
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 getDay,
} from "@/api/common.js";
import { warningList } from "@/api";
import assignTaskView from "./assignTaskView";
import { export_json_to_excel } from "@/vendor/Export2Excel.js";
const typeLists = {
 abnormals: [
  {
   label: "门铃异响",
   value: 0,
  },
  {
   label: "设备离线",
   value: 1,
  },
  {
   label: "自动重启",
   value: 2,
  },
  {
   label: "UUID重复",
   value: 3,
  },
  {
   label: "房间号错误",
   value: 4,
  },
 ],
 abnormalStatus: [
  {
   label: "持续中",
   value: 0,
  },
  {
   label: "已结束",
   value: 1,
  },
 ],
 sbstatus: [
  {
   label: "在线",
   value: "on",
  },
  {
   label: "离线",
   value: "off",
  },
 ],
 // ckList:this.ckList
};
export default {
 components: {
  assignTaskView,
 },
 data() {
  return {
   show: false,
   total: 0,
   pageNum: 1,
   pageSize: 10,
   execlTab: [
    // {
    //  label: "门店",
    //  value: "hotelName",
    // },
    {
     label: "房间号",
     value: "roomNumber",
     width: 80,
    },
    {
     label: "UUID",
     value: "mainboardId",
     width: 280,
    },
    {
     label: "版本号",
     value: "version",
     width: 60,
    },
    {
     label: "插卡状态",
     value: "identity",
     solt_TEXT: "ckList",
     width: 100,
    },
    {
     label: "主机类型",
     value: "mainframeType",
     width: 100,
    },

    {
     label: "异常类型",
     value: "warningType",
     solt_TEXT: "abnormals",
     width: 80,
    },
    {
     label: "开始时间",
     value: "startTime",
     width: 140,
    },
    {
     label: "结束时间",
     value: "endTime",
     width: 140,
    },
    {
     label: "持续时长",
     value: "longTimes",
     width: 150,
    },
    {
     label: "当前设备状态",
     value: "basicstatus",
     solt_TEXT: "sbstatus",
     width: 100,
    },
    {
     label: "异常状态",
     value: "state",
     solt_TEXT: "abnormalStatus",
     width: 100,
    },
    {
     label: "操作",
     type: "operation",
    },
   ],
   defaultTime: ["00:00:00", "23:59:59"],
   searchForm: {
    brandId: null,
    hotelId: null,
    mainframeType: null,
    roomNumber: null,
    status: null,
    operationType: null,
    times: null,
   },
   listQuery: {},
   tablist: [],
   loading: false,
  };
 },
 computed: {
  hotelData() {
   return this.$store.state.hotelData;
  },
  hostType() {
   return this.$store.state.hostType;
  },
  typeLists() {
   let obj = typeLists;
   obj.orderTypeList = this.$store.state.orderTypeList;
   obj.ckList = this.$store.state.ckList;
   obj.orderStatusList = this.$store.state.orderStatusList;
   return obj;
  },
 },
 created() {
  //  this.initTimes()
 },
 methods: {
  // 根据值显示信息
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  // 时长
  showLongTimes(start, end) {
   return getLongDateTime(start, end);
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   //  this.searchForm = {};
   this.$refs.searchForm.resetFields();
   this.getSearchData();
   this.getList();
  },
  getSearchData() {
   this.listQuery = {};
   for (let key in this.searchForm) {
    if (key == "times") {
     if (this.searchForm.times) {
      this.listQuery.startDate = this.searchForm.times[0];
      this.listQuery.endDate = this.searchForm.times[1];
     } else {
      this.listQuery.startDate = this.listQuery.endDate = null;
     }
    } else if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
    this.listQuery.hotelId = this.hotelData.id;
   }
  },
  // 导出
  exportExcel(i) {
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$message.warning("未加载到数据");
     this.downLoading = false;
     return;
    }
    let tHeader = [];
    let filterVal = [];
    let execlTab = JSON.parse(JSON.stringify(this.execlTab));
    execlTab.forEach((v) => {
     if (v.label != "操作") {
      tHeader.push(v.label);
      filterVal.push(v.value);
     }
    });
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
    setTimeout(() => {
     export_json_to_excel(tHeader, data, `异常记录`);
     this.downLoading = false;
    }, 500);
   }
  },

  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    obj.warningType = showLabel(v.warningType, this.typeLists.abnormals);
    obj.identity = showLabel(v.identity, this.typeLists.ckList);
    obj.longTimes = this.showLongTimes(v.startTime, v.endTime);
    obj.basicstatus = showLabel(v.basicstatus, this.typeLists.sbstatus);
    obj.state = showLabel(v.state, this.typeLists.abnormalStatus);
    arr.push(obj);
   });
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },
  // 列表数据
  getList() {
   this.loading = true;
   let data={
     ...this.listQuery,
    source:'workbench',
   }
   warningList(this.pageNum, this.pageSize, data)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.rows;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },

  //  分派
  assignTask(item) {
   let list = [];
   let typeName = showLabel(item.warningType, this.typeLists.abnormals);
   list.push({
    brandId: item.brandId,
    hotelId: item.hotelId,
    hotelName: item.hotelName,
    roomNumber: item.roomNumber,
    faultId: item.id,
    startTime: item.startTime,
    typeName,
   });
   this.$refs.assignTaskView.openSet(list, 2);
  },
  assignTaskAll() {
   let list = [];
   this.multipleSelection.forEach((item) => {
    let typeName = showLabel(item.warningType, this.typeLists.abnormals);
    list.push({
     brandId: item.brandId,
     hotelId: item.hotelId,
     hotelName: item.hotelName,
     roomNumber: item.roomNumber,
     faultId: item.id,
     startTime: item.startTime,
     typeName,
    });
   });

   if (list.length) {
    this.$refs.assignTaskView.openSet(list, 2);
   } else {
    let text = this.multipleSelection.length
     ? "所选记录已全部分派工单"
     : "请选择需要分派的记录";
    this.$message.warning(text);
   }
  },
  handleSelectionChange(val) {
   this.multipleSelection = val;
  },

  // openDialog() {
  //  this.show = true;
  //   this.handleSearch();
  // },
  openDialog() {
   this.show = true;
   if(this.tablist&&this.tablist.length) return
   this.initTimes();
  },
  initTimes() {
   let today = [getDay(-7),getDay()];
   this.searchForm.times = [
    `${today[0]} ${this.defaultTime[0]}`,
    `${today[1]} ${this.defaultTime[1]}`,
   ];
   this.handleSearch();
  },
  // initTimes() {
  //  let today = getDay();
  //  this.searchForm.times = [
  //   `${today} ${this.defaultTime[0]}`,
  //   `${today} ${this.defaultTime[1]}`,
  //  ];
  
  // },
 },
};
</script>


<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");

.topTab {
 //  border-bottom: 3px solid rgb(80, 80, 80);
 padding-bottom: 20px;
 //  margin-bottom: 10px;
}

.roomStatusName {
 font-weight: 600;
}
.isSOS {
 color: #ff4d4d;
}
.isQingLi {
 color: #409eff;
}
.isMenCi {
 color: #e6a23c;
}
</style>