var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demo  theBar borderBar " }, [
    _c("div", { staticClass: "title flexSb" }, [
      _c("div", [_vm._v("\n   下单渠道\n\n  ")]),
      _c("div", { staticClass: "openIcon flexCenter" }, [
        _c("i", {
          staticClass: "el-icon-search iconColor",
          on: { click: _vm.openDetail },
        }),
      ]),
    ]),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cbenchContent" }, [
      _c("div", { staticClass: "chartBar", attrs: { id: "echartOrder" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }