<template>
 <div class="demo theBar borderBar">
  <div class="title flexSb">
   <div>
    工作量
   </div>
   <div class="openIcon flexCenter">
    <!-- <el-dropdown class="user-name" trigger="click" @command="handleCommand">
     <span class="el-dropdown-link">
      {{type==1?'7天':"30天"}}
      <i class="el-icon-caret-bottom"></i>
     </span>
     <el-dropdown-menu slot="dropdown" style="padding:0">
      <el-dropdown-item divided command="1"  style="border:0;margin-top:0">7天</el-dropdown-item>
      <el-dropdown-item divided command="2">30天</el-dropdown-item>
     </el-dropdown-menu>
    </el-dropdown> -->
    <i class="el-icon-search iconColor ml" @click="openDetail"></i>
   </div>
  </div>
  <div class="cbenchContent">
   <div id="workerTotal" class="chartBar"></div>
  </div>
  <workDetail ref="workDetail"></workDetail>
 </div>
</template>
<script>
import CountTo from "vue-count-to";
import { getDay } from "@/api/common.js";
import echarts from "echarts";
import workDetail from "./workDetail.vue";
// import { getOptions } from "./horizontalBar/barConfig2";

import { GET_7DAYS_WORK } from "@/api";
// import { isPhone } from "@/api/common";

export default {
 components: {
  CountTo,
  workDetail,
 },
 data() {
  return {
   type: 1,
   barData: {
    x: [
     21, 23, 18, 29, 28, 21, 19,
     // 24,20,26,25
    ],
    x2: [
     28, 21, 26, 24, 25, 18, 26,
     // 24,20,26,25
    ],
    y: [],
   },
  };
 },
 computed: {
  hotelData() {
   return this.$store.state.hotelData;
  },
 },
 mounted() {
  this.workerTotal = this.$echarts.init(document.getElementById("workerTotal"));
  window.addEventListener("resize", () => {
   this.workerTotal.resize();
  });
  this.getSevenPms();
  this.daylineInter = null;
  this.daylineInter = setInterval(() => {
    this.getSevenPms();
  }, 60 * 1000);
 },
 
 beforeDestroy() {
  clearInterval(this.daylineInter);
 },
 methods: {
  openDetail() {
   this.$refs.workDetail.openDialog();
  },
  handleCommand(val) {
   if (this.type != val) {
    this.type = val;
   }
  },
  getOptions(val, title) {
   return {
    grid: {
     left: "5%",
     right: "5%",
     bottom: "5%",
     //  top: "10%",
     top: 50,
     containLabel: true,
    },
    tooltip: {
     trigger: "axis",
     axisPointer: {
      type: "none",
     },
    },
    legend: {
     top: "5%",
     right: "1%",
     icon:
      "path://M512 960C264.576 960 64 759.424 64 512S264.576 64 512 64s448 200.576 448 448-200.576 448-448 448z m0-268.8a179.2 179.2 0 1 0 0-358.4 179.2 179.2 0 0 0 0 358.4z",
     textStyle: {
      color: "#222",
     },
     data: ["做房", "查房"],
    },
    dataZoom: [
     {
      type: "slider",
      yAxisIndex: 0,
      show: false,
      filterMode: "empty",
     },
     {
      type: "inside",
      show: false,
      // start: 0,
      // end: val.x.length > 15 ? 35 : 100,
      yAxisIndex: 0,
      filterMode: "empty",
     },
    ],

    xAxis: [
     {
      type: "category",
      inverse: true,
      axisLine: { show: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: {
       show: true,
       textStyle: {
        color: "#8492A9",
       },
      },
      data: val.y,
     },
    ],
    color: ["#3B72FF", "#D0DDFF"],
    yAxis: {
     type: "value",
     axisLine: { show: false },
     axisTick: { show: false },
     splitLine: { show: false },
     axisLabel: {
      show: true,
      textStyle: {
       color: "#8492A9",
      },
     },
    },

    series: [
     {
      name: "做房",
      type: "bar",
      barWidth: 15,
      itemStyle: {
       normal: {
        // color: "#3B72FF",
        barBorderRadius: 15,
       },
      },
      data: val.x,
     },
     {
      name: "查房",
      type: "bar",
      barWidth: 15,
      itemStyle: {
       normal: {
        // color: "#D0DDFF",
        barBorderRadius: 15,
       },
      },
      // barWidth: y_Font(),
      data: val.x2,
     },
    ],
   };
  },
  getTime() {
   this.barData.y = [];
   for (let i = -7; i < 0; i++) {
    let t = getDay(i);
    t = t.substring(5, t.length);
    this.barData.y.push(t);
   }
  },
  resize() {
   this.workerTotal.resize();
  },

  // 获取七日内的pms 出租率情况
  async getSevenPms() {
   let hotelId = this.hotelData.id;
   GET_7DAYS_WORK({ hotelId }).then((res) => {
    this.barData.y = [];
    this.barData.x = [];
    this.barData.x2 = [];
    let list = res.data.data;
    list.forEach((item) => {
     let t = item.date.substring(5, item.date.length);
     this.barData.y.unshift(t);
     this.barData.x.unshift(item.cleanRoomCount);
     this.barData.x2.unshift(item.checkRoomCount);
     this.initLine();
    });
    console.log(list);
   });
  },
  initLine() {
   this.workerTotal.setOption(this.getOptions(this.barData, "工作量"));
  },
 },
};
</script>
<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");
.demo {
 width: 100%;
 height: 100%;
 .cbenchContent {
  width: 100%;
  height: calc(100% - 5vh);
 }
}
</style>