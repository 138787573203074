var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bench" },
    [
      _c("Headers"),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 5 } }, [
            _c("div", { staticClass: " theRoomTotal " }, [_c("roomTotal")], 1),
            _c("div", { staticClass: " theTop  mt20 " }, [_c("TopPage")], 1),
            _c(
              "div",
              { staticClass: "mt20 placeOrder" },
              [_c("placeOrder", { ref: "placeOrder" })],
              1
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 19 } },
            [
              _c(
                "el-row",
                { staticClass: "row1", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 10 } }, [_c("tab4")], 1),
                  _c("el-col", { attrs: { span: 7 } }, [_c("menuSet")], 1),
                  _c("el-col", { attrs: { span: 7 } }, [_c("workSpeed")], 1),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "row2 mt20", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { span: 9 } }, [_c("workTotal")], 1),
                  _c("el-col", { attrs: { span: 8 } }, [_c("checkIns")], 1),
                  _c("el-col", { attrs: { span: 7 } }, [_c("loss")], 1),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }