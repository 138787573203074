<template>
 <div class="demo theBar borderBar">
  <div class="title flexSb">
   <div class="flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <!-- <i class="el-icon-tickets iconColor"></i> -->
    <span class="ml">
     快捷功能
    </span>
   </div>
   <el-button type="myColor" size="mini" @click="openDiaoLog">快捷设置</el-button>

  </div>
  <div class=" menuListSwiper scrollBar scroll2" v-if="showType==1">
 
   <div class=" flexWrap menuBar" v-if="items&&items.length">
     <div class="flexCenter menuItem menus3"  v-for="(item,index) in items" :key="index" @click="openPage(item.path)">

      <div class="menuIcon flexCenter" :style="`background-color:${item.color};`">
       <i class="el-icon-tickets "></i>
      </div>
      <div class="ml menuName">
       <el-tooltip class="item" effect="dark" :disabled="!(item.menuName&&item.menuName.length>5)" :content="item.menuName" placement="top-start">
        <span>
         {{item.menuName}}
        </span>
       </el-tooltip>
      </div>
     </div>
     
    </div>
    <el-empty v-else :image-size="80" description=" ">
      <el-button type="primary" size="mini" @click="openDiaoLog">马上去设置</el-button>
    </el-empty>

   <!-- </el-carousel-item>
    </el-carousel> -->
   <!-- <el-empty v-else :image-size="200"></el-empty> -->
   <!-- <el-empty v-else>
     <el-button type="primary">前往设置</el-button>
    </el-empty> -->
   <!-- </div> -->
  </div>
  <div class=" menuListSwiper  " v-if="showType==2">
   <div class="menus">
   <el-carousel v-if="menuPages" indicator-position="outside" arrow="always" trigger="click" :autoplay="false" height="calc(100% - 3vh)" style=" height: 100%;background-color: #fff">
     <el-carousel-item v-for="itemIndex in menuPages" :key="itemIndex">
   <div class="flexBar flexWrap menuBar">
    <!-- <div  class="menus3 "> -->
     <div class="flexCenter menuItem menus3" :style="`height:${3/pageSize*100}%`" v-for="(item,index) in pageSize" v-if="(itemIndex - 1) * pageSize + index < items.length" :key="index" @click="openPage(showItem(itemIndex,index).path)">
      
      <div class="menuIcon flexCenter" :style="`background-color:${showItem(itemIndex,index).color};`">
       <i class="el-icon-tickets "></i>
      </div>
      <div class="ml menuName">
       <el-tooltip class="item" effect="dark" :disabled="!(showItem(itemIndex,index)&&showItem(itemIndex,index).menuName.length>5)" :content="showItem(itemIndex,index).menuName" placement="top-start">
        <span>
       
       {{showItem(itemIndex,index).menuName}}
        </span>
       </el-tooltip>
      </div>


     </div>

   </div>

   </el-carousel-item>
    </el-carousel>
    <el-empty v-else :image-size="60" description=" ">
      <el-button type="primary" size="mini" @click="openDiaoLog">马上去设置</el-button>
    </el-empty>
   </div>
  </div>
  <menuDiaolog ref="menuDiaolog"></menuDiaolog>
 </div>
</template>
<script>
import menuDiaolog from "./menuDiaolog.vue";
import {
  getMyMenus
} from "@/api"
export default {
  props: {
  showType: {
   type: String,
   default: "1",
  },
 },
 components: {
  menuDiaolog,
 },

 data() {
  return {
   pageSize: 9,
   items: [],
  };
 },
 computed: {
  menuPages() {
   if (this.items) return Math.ceil(this.items.length / this.pageSize);
   return 0;
  },
 },
 created() {
  this.getList()
 },
 methods: {
   openPage(url='/'){
     let routeUrl = this.$router.resolve({
          path:url,
     })
    //  console.log(this.$router);
    //  console.log(routeUrl);
     window.open(routeUrl.href, '_blank')

   },
  openDiaoLog() {
    let list=JSON.parse(JSON.stringify(this.items))
   this.$refs.menuDiaolog.openDialog(list);
  },

  showItem(itemIndex, index) {
    if ((itemIndex - 1) * this.pageSize + index < this.items.length) {
     return this.items[(itemIndex - 1) * this.pageSize + index];
    }
  },
  getList(){
    getMyMenus().then(res => {
        if(res.data.code=='000000'){
          this.items=res.data.data
        }
    })
  },
  
 },
};
</script>
<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");
.menuBar{
  display: flex;
}
.demo {
 width: 100%;
 height: 100%;
 .menuListSwiper {
  width: 100%;
  height: calc(100% - 5vh - 12px);
 }
 .scrollBar{
 overflow-y: auto;
 }
}
.menuIcon {
 //  padding: 0.3vh 0.2vw;
 width: 1.3vw;
 min-width: 1.3vw;
 height: 1.3vw;
 
 border-radius: 6px;
 i {
  font-size: 12px;
  color: #fff;
 }
}
.menuItem {
//  width: 100%;
//  height: 100%;
 cursor: pointer;
 font-size: 0.8vw;
 white-space: nowrap;
}
.menuName {
 overflow: hidden;
 text-overflow: ellipsis;
 width: 60%;
 text-align: left;
}
</style>