<template>
 <div class="my-box">

  <el-dialog top="15%" :visible.sync="showDialog" width="30%" :close-on-click-modal="false">
   <div slot="title" class="title2 flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <!-- <i class="el-icon-tickets iconColor"></i> -->
    <span class="ml">
     指派任务
    </span>
   </div>
   <el-form :model="addform" ref="addform" :rules="myrules" label-width="100px" v-loading="selectLoading">
    <el-form-item label="指派给" prop="employeeId">
     <el-select v-model="addform.employeeId" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in workerList" :key="index" :label="item.employeeName" :value="item.employeeId"></el-option>
     </el-select>
    </el-form-item>
    <!-- <el-form-item label="评分" prop="rate" v-if="type==3">
     <div class="order-rate">
      <el-rate v-model="addform.rate"></el-rate>

     </div>
    </el-form-item> -->

   </el-form>
   <div class="dialog-footer">
    <el-button @click="showDialog=false">取 消</el-button>
    <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import {
 getWorkerList,
 updateOrderDetail,
 finishWorkOrder,
 orderUpdateBatch,
 faultOrderBatch,
} from "@/api";
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 getDay,
} from "@/api/common.js";

export default {
 data() {
  return {
   abnormals: [
    {
     label: "门铃异响",
     value: 0,
    },
    {
     label: "设备离线",
     value: 1,
    },
    {
     label: "自动重启",
     value: 2,
    },
    {
     label: "UUID重复",
     value: 3,
    },
    {
     label: "房间号错误",
     value: 4,
    },
   ],
   type: null,
   loading: false,
   showDialog: false,
   selectLoading: false,
   addform: {
    employeeId: null,
    // rate: 5,
   },
   workerList: [],
   orderList: [],
   myrules: {
    employeeId: [{ required: true, message: "请选择", trigger: "change" }],
   },
  };
 },
 computed: {
  cardTypes() {
   return this.$store.state.cardTypes;
  },
 },
 methods: {
  openSet(orderList, type) {
   console.log(orderList, type);
   this.orderList = JSON.parse(JSON.stringify(orderList));
   this.type = type;
   this.showDialog = true;
   let item = orderList[0];
   this.addform.employeeId = null;
   let data = {};
   data.brandId = item.brandId;
   data.hotelId = item.hotelId;
   data.orderType = type == 2 ? 1 : item.orderType;
   this.getWorkerListReq(data);
  },
  // 获取员工列表   派单（服务员、维修工）
  getWorkerListReq(data) {
   this.selectLoading = true;
   getWorkerList(data)
    .then((res) => {
     this.selectLoading = false;
     this.workerList = res.data.data;
    })
    .catch((err) => {
     this.selectLoading = false;
    });
  },
  confirmAdd(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     switch (this.type) {
      case 1:
       this.submitReq();
       break;
      case 2:
       this.faultOrderBatchReq();
       break;
      case 3:
       this.finishWorkOrderReq();
       break;
      default:
       break;
     }
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  // 分派
  submitReq() {
   this.loading = true;
   let { employeeName, employeeId } = this.workerList.find(
    (item) => item.employeeId == this.addform.employeeId
   );
   console.log(employeeName, "-employeeName", employeeId);
   this.orderList.forEach((item) => {
    item.employeeId = employeeId;
    item.employeeName = employeeName;
   });
   let data = {};
   data.orderList = this.orderList;
   //  data.orderId = this.item.orderId;
   //  //  data.orderStatus = this.item.orderStatus ? 2 : 1;
   //  data.employeeId = this.addform.employeeId;
   console.log(data);

   orderUpdateBatch(data)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("分派成功");
      this.showDialog = false;
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },
  showLabelText(val, list) {
   return showLabel(val, list);
  },

  faultOrderBatchReq() {
   this.loading = true;
   let { employeeName, employeeId, cardSn } = this.workerList.find(
    (item) => item.employeeId == this.addform.employeeId
   );
   console.log(employeeName, "-employeeName", employeeId);
   this.orderList.forEach((item) => {
    item.employeeId = employeeId;
    item.employeeName = employeeName;
    item.cardSn = cardSn;
   });
   let data = {};
   data.list = this.orderList;
   console.log(data);
   faultOrderBatch(data)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("分派成功");
      this.showDialog = false;
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },
  finishWorkOrderReq() {
   let data = {};
   data.orderId = this.item.orderId;
   data.score = this.addform.rate;
   data.orderStatus = 5;
   finishWorkOrder(data)
    .then((res) => {
     console.log(res);
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("验收成功");
      this.showDialog = false;
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
    })
    .catch((err) => {
     console.log(err);
     this.loading = false;
    });
  },
 },
};
</script>
<style lang="scss" scoped>
</style>


