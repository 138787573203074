<template>
 <div class="messageDialog">
  <!-- 客房管理 -->
  <el-dialog title="消息提示" :close-on-click-modal="false" top="3%" :visible.sync="show" width="80%" class="roomManagementDialog">
   <div slot="title" class="title2 flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <span class="ml">
     入住情况
    </span>
   </div>
   <el-form label-width="70px" ref="searchForm" :model="searchForm">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="5">
      <el-form-item label="开始时间">
       <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" style="width:100%" :picker-options="startDateDisabled" default-time="00:00:00" v-model="searchForm.startTime" type="datetime" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="结束时间">
       <el-date-picker v-model="searchForm.endTime" style="width:100%" :picker-options="endDateDisabled" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" default-time="23:59:59" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>

     <el-col :span="4">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>

      </div>
     </el-col>
     <el-col :span="10">
      <el-form-item label="" label-width="0">

       <span>总出租量</span>
       <span class="theNum">{{sum.totalRentedCount|returnNum}}</span>
       <span style="margin-right:20px"></span>
       <span>平均单日出租量</span>
       <span class="theNum">{{sum.avgCount|returnNum}}</span>
       <span style="margin-right:20px"></span>
       <span>平均出租率</span>
       <span class="theNum">{{sum.rate|returnRate}}</span>

      </el-form-item>
     </el-col>
    </el-row>

   </el-form>
   <div>

    <el-table :data="tablist" v-loading="loading" id="tab" :height="500" style="width: 100%" class="managerTablist" stripe header-row-class-name="tabHeader">
     <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :align="item.type=='operation'?'center':''" :label="item.label">
      <template slot-scope="scope">
       <div v-if="item.type=='operation'">
        <el-button type="primary" size="mini" @click="assignTask(scope.row)">工作轨迹</el-button>
       </div>
       <div v-if="item.solt_TEXT">
        {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
       </div>
      <div v-if="item.value=='rate'">
        {{`${scope.row[item.value] ? scope.row[item.value]  : 0}%`}}
       </div>
       <div v-else>
        {{scope.row[item.value]}}
       </div>
      </template>
     </el-table-column>
    </el-table>
   </div>
   <div slot="footer" class="footerPage">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
    <div>
     <el-button size="mini" type="primary" @click="exportExcel(0)">导出</el-button>

    </div>
   </div>
  </el-dialog>

 </div>
</template>
<script>
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 labelShowDate,
 getDay
} from "@/api/common.js";
import {
 GET_7DAYS_CHECK_IN_LIST
} from "@/api";
import { export_json_to_excel } from "@/vendor/Export2Excel.js";

export default {
     filters:{
      returnNum(val){
         if(val) return val
         return 0
      } ,
      returnRate(val){
          if(val) return `${(val*100).toFixed(2)}%`
         return `0%`
      } ,
    
   },
 data() {
  return {
   show: false,
   total: 0,
   pageNum: 1,
   pageSize: 10,
   execlTab: [
    {
     label: "入住日期",
     value: "date",
    },
    {
     label: "设备出租量",
     value: "count",
    },

    {
     label: "设备出租率",
     value: "rate",
    },

    // {
    //  label: "操作",
    //  type: "operation",
    // },
   ],
   defaultTime: ["00:00:00", "23:59:59"],
   startDateDisabled: {
    // 限制日期
    disabledDate: this.startDisabled,
   },
   endDateDisabled: {
    // 限制日期
    disabledDate: this.endDisabled,
   },
   searchForm: {
    startTime: null,
    endTime: null,
   },
   listQuery: {},
   tablist: [],
   loading: false,
     sum:{},
  };
 },
 computed: {
  
   hotelData() {
   return  this.$store.state.hotelData;
  },
 },
 methods: {
  startDisabled(t) {
   if (this.searchForm.endTime && t)
    return new Date(t).getTime() > new Date(this.searchForm.endTime).getTime();
   return false;
  },
  endDisabled(t) {
   if (this.searchForm.startTime && t)
    return (
     new Date(t).getTime() < new Date(this.searchForm.startTime).getTime()
    );
   return false;
  },
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  getDay(val) {
   return labelShowDate(val);
  },
  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   //  this.searchForm = {};
   this.$refs.searchForm.resetFields();
   this.getSearchData();
   this.getList();
  },
 getSearchData() {
   this.listQuery = {};
   for (let key in this.searchForm) {
    if (key == "times") {
     if (this.searchForm.times) {
      this.listQuery.startTime = this.searchForm.times[0];
      this.listQuery.endTime = this.searchForm.times[1];
     } else {
      this.listQuery.startTime = this.listQuery.endTime = null;
     }
    } else if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
   }
   this.listQuery.hotelId=this.hotelData.id
   this.listQuery.type=1
  },
  // 导出
  exportExcel(i) {
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$message.warning("未加载到数据");
     this.downLoading = false;
     return;
    }
    let tHeader = [];
    let filterVal = [];
    this.execlTab.forEach((v) => {
     tHeader.push(v.label);
     filterVal.push(v.value);
    });
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
    setTimeout(() => {
     export_json_to_excel(tHeader, data, "入住情况");
     this.downLoading = false;
    }, 500);
   }
  },
  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    obj.rate = `${v.rate ? v.rate  : 0}%`;
    arr.push(obj);
   });
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },
  // 列表数据
  getList() {
    this.loading = true;
    console.log(this.listQuery, " console.log(this.listQuery);");
    // GET_7DAYS_CHECK_IN_LIST(this.pageNum, this.pageSize, this.listQuery)
  
     GET_7DAYS_CHECK_IN_LIST(this.pageNum, this.pageSize, this.listQuery)
    .then((res) => {
      console.log(res,'-------GET_7DAYS_CHECK_IN_LIST');
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.list.records;
      this.sum = res.data.data.sum;
      this.total = res.data.data.list.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });

  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },

  //  分派
  assignTask(item) {
   this.$refs.trackDetails.openSet(item, 1);
  },

  
  openDialog() {
   this.show = true;
  //  this.getSearchData();
  //  this.getList();
  if(this.tablist.length) return
    this.initTimes();
  },
  initTimes() {
    let days = [getDay(-7),getDay(-1)];
    console.log(days,'-------------');
    this.searchForm.startTime= `${days[0]} ${this.defaultTime[0]}`;
    this.searchForm.endTime= `${days[1]} ${this.defaultTime[1]}`
   this.handleSearch();
  },

 },
};
</script>


<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");

.topTab {
 //  border-bottom: 3px solid rgb(80, 80, 80);
 padding-bottom: 20px;
 //  margin-bottom: 10px;
}

.roomStatusName {
 font-weight: 600;
}
.isSOS {
 color: #ff4d4d;
}
.isQingLi {
 color: #409eff;
}
.isMenCi {
 color: #e6a23c;
}
</style>