<template>
 <div class="messageDialog">
  <!-- 客房管理 -->
  <el-dialog title="消息提示" top="3%" :visible.sync="show" width="80%" class="roomManagementDialog" :close-on-click-modal="false">
   <div slot="title" class="title2 flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <span class="ml">
     工作效率
    </span>
   </div>
   <el-form label-width="70px" ref="searchForm" :model="searchForm">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="5">
      <el-form-item label="开始时间" prop="startDate">
       <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" style="width:100%" :picker-options="startDateDisabled" default-time="00:00:00" v-model="searchForm.startDate" type="datetime" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>
     <el-col :span="5">
      <el-form-item label="结束时间" prop="endDate">
       <el-date-picker v-model="searchForm.endDate" style="width:100%" :picker-options="endDateDisabled" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" default-time="23:59:59" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>

     <el-col :span="4">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>

      </div>
     </el-col>
     <el-col :span="10">
      <el-form-item label="" label-width="0">
       <!-- <span>总做房次数</span>
       <span class="theNum">11</span>
       <span style="margin-right:20px">次</span>
        <span>平均做房率</span>
       <span class="theNum">11</span>
       <span style="margin-right:20px">%</span>
       <span>总查房次数</span>
       <span class="theNum">11</span>
       <span style="margin-right:20px">次</span>
       <span>总查房率</span>
       <span class="theNum">11</span>
       <span style="margin-right:20px">%</span> -->
       <span>总做房次数</span>
       <span class="theNum">{{topData.cleanRoomCount}}</span>
       <span style="margin-right:20px">次</span>
       <span>总做房率</span>
       <span class="theNum">{{topData.cleanRoomRate}}</span>
       <span style="margin-right:20px">%</span>
       <span>总做房平均时长</span>
       <span class="theNum">{{topData.cleanRoomLongTime}}</span>
       <!-- <span style="margin-right:20px">分钟</span> -->

      </el-form-item>
     </el-col>
    </el-row>

   </el-form>
   <div>

    <el-table :data="tablist" v-loading="loading" id="tab" :height="500" style="width: 100%" class="managerTablist" stripe header-row-class-name="tabHeader">
     <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :align="item.type=='operation'?'center':''" :label="item.label">
      <template slot-scope="scope">
       <div v-if="item.type=='operation'">
        <el-button type="primary" size="mini" @click="assignTask(scope.row)">工作轨迹</el-button>
       </div>
       <div v-if="item.solt_TEXT">
        {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
       </div>
       <div v-else-if="item.value=='opsAverageTime'">
        {{showLongTimes(scope.row.opsAverageTime)}}
       </div>
       <div v-else-if="item.value=='endTime'">
        {{showDay(scope.row.endTime)}}
       </div>
       <div v-else-if="item.value=='opsPercent'">
        {{`${scope.row.opsPercent ? scope.row.opsPercent * 100 : 0}%`}}
       </div>

       <div v-else>
        {{scope.row[item.value]}}
       </div>
      </template>
     </el-table-column>
    </el-table>
   </div>
   <div slot="footer" class="footerPage">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
    <div>
     <el-button size="mini" type="primary" @click="exportExcel(0)">导出</el-button>

    </div>
   </div>
  </el-dialog>
  <trackDetails ref="trackDetails" />

 </div>
</template>
<script>
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 labelShowDate,
 getDay
} from "@/api/common.js";
import {
 makeRoomList,
 getBrandSelect,
 getHotelSelect,
 makeRoomOpsRate,
} from "@/api";
import { export_json_to_excel } from "@/vendor/Export2Excel2.js";
import trackDetails from "./trackDetails.vue";
const typeLists = {
 abnormals: [
  {
   label: "门铃异响",
   value: 0,
  },
  {
   label: "设备离线",
   value: 1,
  },
  {
   label: "自动重启",
   value: 2,
  },
  {
   label: "UUID重复",
   value: 3,
  },
  {
   label: "房间号错误",
   value: 4,
  },
 ],
 abnormalStatus: [
  {
   label: "持续中",
   value: 0,
  },
  {
   label: "已结束",
   value: 1,
  },
 ],
 sbstatus: [
  {
   label: "在线",
   value: "on",
  },
  {
   label: "离线",
   value: "off",
  },
 ],
 // ckList:this.ckList
};
export default {
 components: {
  trackDetails,
 },
 data() {
  return {
   show: false,
   total: 0,
   pageNum: 1,
   pageSize: 10,
   execlTab: [
    // {
    //  label: "品牌",
    //  value: "brandName",
    // },
    // {
    //  label: "门店",
    //  value: "hotelName",
    //  width: 280,
    // },
    // {
    //  label: "卡类型",
    //  value: "position",
    //  solt_TEXT: "cardTypes",
    // },
    {
     label: "姓名",
     value: "employeeName",
    },
    {
     label: "卡号",
     value: "cardSn",
    },
    // {
    //  label: "开始时间",
    //  value: "startTime",
    // },
    // {
    //  label: "结束时间",
    //  value: "endTime",
    // },
    {
     label: "做房时间",
     value: "endTime",
    },
    {
     label: "做房次数",
     value: "opsCount",
    },
    {
     label: "做房占比",
     value: "opsPercent",
    },
    {
     label: "平均效率（时长）",
     value: "opsAverageTime",
    },

    {
     label: "操作",
     type: "operation",
    },
   ],
   defaultTime: ["00:00:00", "23:59:59"],
   startDateDisabled: {
    // 限制日期
    disabledDate: this.startDisabled,
   },
   endDateDisabled: {
    // 限制日期
    disabledDate: this.endDisabled,
   },
   searchForm: {
    //  brandId: null,
    // hotelId: this.hotelData.id,
    startDate: null,
    // position: null,
    // cardSn: null,
    // employeeName: null,
    position: 0,
    endDate: null,
   },
   listQuery: {},
   tablist: [],
   loading: false,
   topData:{}
  };
 },
 computed: {
 
  hostType() {
   return this.$store.state.hostType;
  },
  hotelData() {
   return this.$store.state.hotelData;
  },
  typeLists() {
   let obj = typeLists;
   obj.cardTypes = this.$store.state.cardTypes;
   return obj;
  },
 },
 created() {
   this.getTop()
 },
 methods: {
   getTop(){
       let data = {
    cleanRoomRate: 0,
    cleanRoomLongTime: 0,
    cleanRoomCount: 0,
   };
  //  console.log(this.makeData, "--------this.roomForm");
   if (this.makeData) {
    data.cleanRoomCount = this.makeData.cleanRoomCount;
    data.cleanRoomRate =
     this.makeData.cleanRoomCount && this.makeData.checkoutCount
      ? this.makeData.cleanRoomCount / this.makeData.checkoutCount: 0;

      data.cleanRoomRate= Math.round(data.cleanRoomRate * 10000) / 100
    // console.log(
    //  data.cleanRoomRate,
    //  this.makeData.cleanRoomCount,
    //  this.makeData.checkoutCount
    // );
    let cleanRoomLongTime =
     this.makeData.cleanRoomTimeTotal && this.makeData.cleanRoomCount
      ? this.makeData.cleanRoomTimeTotal / this.makeData.cleanRoomCount
      : 0;
    data.cleanRoomLongTime = this.showLongTimes(cleanRoomLongTime);
   }
    this.topData=data
   },
  startDisabled(t) {
   if (this.searchForm.endDate && t)
    return new Date(t).getTime() > new Date(this.searchForm.endDate).getTime();
   return false;
  },
  endDisabled(t) {
   if (this.searchForm.startDate && t)
    return (
     new Date(t).getTime() < new Date(this.searchForm.startDate).getTime()
    );
   return false;
  },
  showDay(val) {
   if (val) return val.substring(0, 10);
  },
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  getDay(val) {
   return labelShowDate(val);
  },
  // 时长
  showLongTimes(val) {
   return secondGetLongTime(val);
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   console.log(this.searchForm,'--this.searchForm');
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   //  this.searchForm = {};
   this.$refs.searchForm.resetFields();
   this.getSearchData();
   this.getList();
  },
  getSearchData() {
    
   for (let key in this.searchForm) {
    if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }else{
      this.listQuery[key]=null
    }
   }
   this.listQuery.hotelId = this.hotelData.id;
  },
  // 导出
  exportExcel(i) {
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$message.warning("未加载到数据");
     this.downLoading = false;
     return;
    }
    let tHeader = [];
    let filterVal = [];
    this.execlTab.forEach((v) => {
     if (v.label != "操作") {
      tHeader.push(v.label);
      filterVal.push(v.value);
     }
    });
    //  const merges = [  'D1:E1', ];
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
    // multiHeader2:tHeader,multiHeader:tHeader,
    setTimeout(() => {
     export_json_to_excel({ tHeader, data, name: "工作效率" });
     this.downLoading = false;
    }, 500);
   }
  },
  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    obj.opsAverageTime = this.showLongTimes(v.opsAverageTime);
    obj.endTime = this.showDay(v.endTime);
    obj.opsPercent = `${v.opsPercent ? v.opsPercent * 100 : 0}%`;
    arr.push(obj);
   });
  //  console.log(arr, "-----");
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },
  // 列表数据
  getList() {
   this.loading = true;
   makeRoomList(this.pageNum, this.pageSize, this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.rows;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
   let data = Object.assign({}, this.listQuery);
   delete data.position;
   makeRoomOpsRate(data).then((res) => {
    // console.log(res);
    if (res.data.code == "000000") {
     this.makeData = res.data.data;
      this.getTop()
    } else {
     this.$message.error("查房统计失败");
    }
   });
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },

  //  分派
  assignTask(item) {
    let data=JSON.parse(JSON.stringify(item))
    data.opsDate=this.showDay(data.endTime)
   this.$refs.trackDetails.openSet(data, 1);
  },

  openDialog() {
   this.show = true;
  //  this.getSearchData();
  //  this.getList();
  if(this.tablist.length) return
    this.initTimes();
  },
  initTimes() {
    let days = [getDay(-7),getDay()];
    console.log(days,'-------------');
    this.searchForm.startDate= `${days[0]} ${this.defaultTime[0]}`;
    this.searchForm.endDate= `${days[1]} ${this.defaultTime[1]}`
   this.handleSearch();
  },
 },
};
</script>


<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");

.topTab {
 //  border-bottom: 3px solid rgb(80, 80, 80);
 padding-bottom: 20px;
 //  margin-bottom: 10px;
}

.roomStatusName {
 font-weight: 600;
}
.isSOS {
 color: #ff4d4d;
}
.isQingLi {
 color: #409eff;
}
.isMenCi {
 color: #e6a23c;
}
</style>