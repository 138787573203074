var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "demo theBar borderBar" },
    [
      _c(
        "div",
        { staticClass: "title flexSb" },
        [
          _vm._m(0),
          _c(
            "el-button",
            {
              attrs: { type: "myColor", size: "mini" },
              on: { click: _vm.openDiaoLog },
            },
            [_vm._v("快捷设置")]
          ),
        ],
        1
      ),
      _vm.showType == 1
        ? _c(
            "div",
            { staticClass: " menuListSwiper scrollBar scroll2" },
            [
              _vm.items && _vm.items.length
                ? _c(
                    "div",
                    { staticClass: " flexWrap menuBar" },
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "flexCenter menuItem menus3",
                          on: {
                            click: function ($event) {
                              return _vm.openPage(item.path)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "menuIcon flexCenter",
                              style: "background-color:" + item.color + ";",
                            },
                            [_c("i", { staticClass: "el-icon-tickets " })]
                          ),
                          _c(
                            "div",
                            { staticClass: "ml menuName" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    disabled: !(
                                      item.menuName && item.menuName.length > 5
                                    ),
                                    content: item.menuName,
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n        " +
                                        _vm._s(item.menuName) +
                                        "\n       "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    }),
                    0
                  )
                : _c(
                    "el-empty",
                    { attrs: { "image-size": 80, description: " " } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.openDiaoLog },
                        },
                        [_vm._v("马上去设置")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.showType == 2
        ? _c("div", { staticClass: " menuListSwiper  " }, [
            _c(
              "div",
              { staticClass: "menus" },
              [
                _vm.menuPages
                  ? _c(
                      "el-carousel",
                      {
                        staticStyle: {
                          height: "100%",
                          "background-color": "#fff",
                        },
                        attrs: {
                          "indicator-position": "outside",
                          arrow: "always",
                          trigger: "click",
                          autoplay: false,
                          height: "calc(100% - 3vh)",
                        },
                      },
                      _vm._l(_vm.menuPages, function (itemIndex) {
                        return _c("el-carousel-item", { key: itemIndex }, [
                          _c(
                            "div",
                            { staticClass: "flexBar flexWrap menuBar" },
                            _vm._l(_vm.pageSize, function (item, index) {
                              return (itemIndex - 1) * _vm.pageSize + index <
                                _vm.items.length
                                ? _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "flexCenter menuItem menus3",
                                      style:
                                        "height:" +
                                        (3 / _vm.pageSize) * 100 +
                                        "%",
                                      on: {
                                        click: function ($event) {
                                          _vm.openPage(
                                            _vm.showItem(itemIndex, index).path
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "menuIcon flexCenter",
                                          style:
                                            "background-color:" +
                                            _vm.showItem(itemIndex, index)
                                              .color +
                                            ";",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-tickets ",
                                          }),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "ml menuName" },
                                        [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                disabled: !(
                                                  _vm.showItem(
                                                    itemIndex,
                                                    index
                                                  ) &&
                                                  _vm.showItem(itemIndex, index)
                                                    .menuName.length > 5
                                                ),
                                                content: _vm.showItem(
                                                  itemIndex,
                                                  index
                                                ).menuName,
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "\n      \n      " +
                                                    _vm._s(
                                                      _vm.showItem(
                                                        itemIndex,
                                                        index
                                                      ).menuName
                                                    ) +
                                                    "\n       "
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          ),
                        ])
                      }),
                      1
                    )
                  : _c(
                      "el-empty",
                      { attrs: { "image-size": 60, description: " " } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: { click: _vm.openDiaoLog },
                          },
                          [_vm._v("马上去设置")]
                        ),
                      ],
                      1
                    ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("menuDiaolog", { ref: "menuDiaolog" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flexItem" }, [
      _c("img", {
        staticClass: "titleImg",
        attrs: {
          src: require("../../../../../assets/benchCss/img/icon-list.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "ml" }, [_vm._v("\n    快捷功能\n   ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }