var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "benchPage  theBar borderBar" }, [
    _c("div", { staticClass: "topIndex flexItem" }, [
      _c("div", { staticClass: "topImg" }, [
        _vm.theTop.monthRanking == 1
          ? _c("img", {
              attrs: {
                src: require("../../../../assets/benchCss/img/Property 1=1.png"),
                alt: "",
              },
            })
          : _vm.theTop.monthRanking == 2
          ? _c("img", {
              attrs: {
                src: require("../../../../assets/benchCss/img/Property 1=2.png"),
                alt: "",
              },
            })
          : _vm.theTop.monthRanking == 3
          ? _c("img", {
              attrs: {
                src: require("../../../../assets/benchCss/img/Property 1=3.png"),
                alt: "",
              },
            })
          : _c("img", {
              attrs: {
                src: require("../../../../assets/benchCss/img/Property 1=4.png"),
                alt: "",
              },
            }),
      ]),
      _c("div", { staticClass: "TopContent" }, [
        _c("div", { staticClass: "theIndex" }, [
          _c("span", [_vm._v("第")]),
          _c("span", { staticClass: "indexNum" }, [
            _vm._v(_vm._s(_vm.theTop.monthRanking)),
          ]),
          _c("span", [_vm._v("名")]),
        ]),
        _c("div", { staticClass: "content2" }, [
          _vm._v(_vm._s(_vm.theTop.name)),
        ]),
      ]),
    ]),
    _vm.list && _vm.list.length
      ? _c("div", { staticClass: "topheader tabRow  flexItem" }, [
          _c("div", { staticClass: "flex2" }, [_vm._v("\n   集团排行榜\n  ")]),
          _c("div", { staticClass: "flex1" }, [_vm._v("\n   本月\n  ")]),
          _c("div", { staticClass: "flex1" }, [_vm._v("\n   七月累积\n  ")]),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "topTab scroll" },
      _vm._l(_vm.list, function (item, index) {
        return _c("div", { key: index, staticClass: "tabRow  flexItem" }, [
          _c("div", { staticClass: "flex2" }, [
            _vm._v("\n    " + _vm._s(item.name) + "\n   "),
          ]),
          _c("div", { staticClass: "flex1" }, [
            _vm._v("\n    " + _vm._s(item.monthRanking) + "\n   "),
          ]),
          _c("div", { staticClass: "flex1" }, [
            _vm._v("\n    " + _vm._s(item.sevenMonthRanking) + "\n   "),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }