
<template>
 <div class="benchPage   theBar borderBar">

  <div class="title ">
   {{hotelData.name}}

   <!-- <div class="openIcon flexCenter">
    <i class="el-icon-search iconColor"></i>
   </div> -->
  </div>
  <div class="flexItem cbenchContent">
   <div class="topImg flexCenter column">
    <div class="indexNum">
     {{total}}
    </div>
    <div class="content2">房间数</div>
   </div>
   <div class="chareImg" id="roomTotal"></div>

  </div>
 </div>
</template>

<script>
import { getRoomTypeTotal, getRoomTotal } from "@/api";
export default {
 data() {
  return {
   total: 0,
   barColor: [
    { color1: "#0263FF", color2: "#67A1FF" },
    { color1: "#FF7824", color2: "#FFAE7C" },
    { color1: "#8E30FF", color2: "#BB83FF" },
    { color1: "#3BB16C", color2: "#89D0A7" },
    { color1: "#FF4267", color2: "#FF8EA4" },
    { color1: "#49C5E0", color2: "#92DCED" },
    { color1: "#FFD469", color2: "#FFE5A5" },
   ],
   x1: [150, 160, 170, 165, 190, 199, 312, 180, 190, 199, 312, 180],
   x2: [180, 140, 200, 105, 195, 129, 212, 100, 195, 129, 212, 100],
   y: ["标准单间", "豪华双人间", "豪华套房", "豪华套房"],
  };
 },

 mounted() {
  this.roomTotal = this.$echarts.init(document.getElementById("roomTotal"));
  window.addEventListener("resize", () => {
   this.roomTotal.resize();
  });
  this.initLine();
  this.getTotal();
  this.placeOrderInter = null;
  this.placeOrderInter = setInterval(() => {
   this.initLine();
  }, 60 * 1000);
 },
 computed: {
  color() {
   return this.$store.state.color;
  },
  hotelData() {
   return this.$store.state.hotelData;
  },
 },

 methods: {
  getTotal() {
   getRoomTotal(this.hotelData.id).then((res) => {
    this.total = res.data.data;
   });
  },
  getData() {
   let serverData1 = {
     name: "房间数",
     type: "bar",
    },
    serverData2 = {
     name: "当前入驻",
     type: "bar",
    };
   serverData1.data = [];
   serverData2.data = [];
   this.x1.forEach((item, index) => {
    serverData1.data.push({
     value: item,
     itemStyle: {
      color: this.barColor[index % this.barColor.length].color1,
     },
    });
   });
   this.x2.forEach((item, index) => {
    serverData2.data.push({
     value: item,
     itemStyle: {
      color: this.barColor[index % this.barColor.length].color2,
     },
    });
   });
   let serverData = [serverData1, serverData2];
   return serverData;
  },
  initLine() {
   getRoomTypeTotal(this.hotelData.id).then((res) => {
    (this.x1 = []), (this.x2 = []), (this.y = []);
    let list = res.data.data;
    list.forEach((item) => {
     this.x1.unshift(item.totalCount ? item.totalCount : 0);
     this.x2.unshift(item.rentedCount ? item.rentedCount : 0);
     this.y.unshift(item.roomTypeName);
    });
    // console.log(this.y);
    this.roomTotal.setOption(this.getOptions());
   });
  },
  resize() {
   this.roomTotal.resize();
  },
  getOptions() {
   return {
    tooltip: {
     trigger: "axis",
     axisPointer: {
      type: "none",
     },
    },
    grid: {
     top: 10,
     bottom: 10,
     left: 10,
     right: 10,
     containLabel: true,
    },
    xAxis: {
     type: "value",
     axisLine: { show: false },
     axisLabel: { show: false },
     axisTick: { show: false },
     splitLine: { show: false },
    },
    color: this.color,
    yAxis: {
     type: "category",
     data: this.y,
     axisLine: { show: false },
     axisTick: { show: false },
     splitLine: { show: false },
    },
    series: this.getData(),
   };
  },
 },
};
</script>

<style lang="scss" scoped>
@import url("../../../../assets/benchCss/base.scss");
.chareImg {
 height: 100%;
 flex: 1;
}
.benchPage {
 width: 100%;
 height: 100%;
 .cbenchContent {
  width: 100%;
  height: calc(100% - 5vh);
 }
}

.topImg {
 width: 6vw;
 min-width: 6vw;
 height: 100%;
}

.indexNum {
 font-size: 3.8vh;
 color: #f69e1e;
 font-weight: bold;
}
</style>
