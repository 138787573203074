<template>
 <div class="messageDialog">
  <!-- 客房管理 -->
  <el-dialog title="消息提示" top="3%" :visible.sync="show" width="80%" class="roomManagementDialog" :close-on-click-modal="false">
   <div slot="title" class="title2 flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <!-- <i class="el-icon-tickets iconColor"></i> -->
    <span class="ml">
     服务工单
    </span>
   </div>
   <el-form label-width="50px" ref="searchForm" :model="searchForm">
    <el-row :gutter="10" style="margin-bottom: 0">
     <el-col :span="4">
      <el-form-item label="房间号" label-width="60px" prop="roomNumber">
       <el-input v-model="searchForm.roomNumber" clearable></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="6">
      <el-form-item label="时间" prop="times">
       <el-date-picker v-model="searchForm.times" style="width:100%" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" :default-time="['00:00:00', '23:59:59']" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>
     <el-col :span="4">
      <el-form-item label="工单类型" label-width="80px" prop="orderType">
       <el-select v-model="searchForm.orderType" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.orderTypeList" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col>
     <!-- <el-col :span="4">
      <el-form-item label="工单状态" label-width="80px" prop="orderStatus">
       <el-select v-model="searchForm.orderStatus" clearable filterable placeholder="请选择">
        <el-option v-for="(item,index) in typeLists.orderStatusList" :key="index" :label="item.label" :value="item.value"></el-option>
       </el-select>
      </el-form-item>
     </el-col> -->
     <el-col :span="4">
      <el-form-item label="处理人" label-width="80px" prop="employeeName">
       <el-input v-model="searchForm.employeeName" clearable></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="6">
      <div class="grid-content reset-button">
       <el-button size="mini" type="primary" @click="handleSearch">查询</el-button>
       <el-button size="mini" @click="reset">重置</el-button>
       <el-button size="mini" type="primary" icon="el-icon-search" v-if="orderView" @click="openOrderView">更多</el-button>

      </div>
     </el-col>
    </el-row>

   </el-form>
   <div>

    <el-table :data="tablist" v-loading="loading" id="tab" :height="500" style="width: 100%" class="managerTablist" stripe header-row-class-name="tabHeader" @selection-change="handleSelectionChange">
     <el-table-column type="selection" width="50" align="center">
     </el-table-column>
     <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :align="item.type=='operation'?'center':''" :label="item.label">
      <template slot-scope="scope">
       <div v-if="item.type=='operation'">
        <!-- showtoOther&& -->
        <el-button type="primary" v-if="scope.row.orderStatus<4" size="mini" @click="assignTask(scope.row)">分派</el-button>
        <!-- ||scope.row.orderStatus==5 -->
        <!-- showrework&& -->
        <!-- <el-button type="warning" v-if="(scope.row.orderStatus==4)" size="mini" @click="rework(scope.row)">返工</el-button> -->
        <!-- showcheckOrder&& -->
        <!-- <el-button type="success" v-if="scope.row.orderStatus==4" size="mini" @click="orderFinish(scope.row)">验收</el-button> -->
        <!--  v-if="showDelete" -->
        <!-- <el-button type="danger" size="mini" @click="del(scope.row)">删除</el-button> -->
       </div>
       <div v-if="item.solt_TEXT">
        {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
       </div>
       <div v-else>
        {{scope.row[item.value]}}
       </div>
      </template>
     </el-table-column>
    </el-table>
   </div>
   <div slot="footer" class="footerPage">
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination>
    <div>

     <el-button size="small" @click="exportExcel(0)">导出</el-button>
     <el-button size="small" @click="show =false">取消</el-button>
     <el-button size="small" type="primary" v-if="showBatch" @click="assignTaskAll">分派给</el-button>

    </div>
   </div>
  </el-dialog>
  <assignTaskView ref="assignTaskView" />

 </div>
</template>
<script>
import { getLongDateTime, secondGetLongTime, showLabel } from "@/api/common.js";
import { getServiceOrders, deleteWorkOrder, updateOrderDetail } from "@/api";
import assignTaskView from "./assignTaskView";
import { export_json_to_excel } from "@/vendor/Export2Excel.js";

export default {
 components: {
  assignTaskView,
 },
 //  props: {
 //   tablist: {
 //    default: [],
 //    type: Array,
 //   },
 //  },
 data() {
  return {
   selectList: [],
   show: false,
   total: 0,
   pageNum: 1,
   pageSize: 10,
   execlTab: [
    // {
    //  label: "品牌",
    //  value: "brandName",
    // },
    // {
    //  label: "门店",
    //  value: "hotelName",
    //  width: 280,
    // },
    {
     label: "房号",
     value: "roomNumber",
     width: 80,
    },

    {
     label: "工单类型",
     value: "orderType",
     solt_TEXT: "orderTypeList",
     width: 80,
    },
    {
     label: "工单号",
     value: "orderId",
     width: 160,
    },
    {
     label: "描述",
     value: "remark",
    },
    {
     label: "工单状态",
     value: "orderStatus",
     solt_TEXT: "orderStatusList",
     width: 80,
    },
    {
     label: "处理人",
     value: "employeeName",
     width: 120,
    },
    {
     label: "创建时间",
     value: "createTime",
     width: 150,
    },
    // {
    //  label: "执行时间",
    //  value: "finishTime",
    //  width: 150,
    // },

    {
     label: "操作",
     type: "operation",
     width: 150,
    },
   ],
   searchForm: {
    brandId: null,
    hotelId: null,
    roomNumber: null,
    times: null,
    orderType: null,
    orderStatus: null,
    employeeName: null,
   },
   listQuery: {},
   tablist: [],
   loading: false,
  };
 },
 computed: {
  orderView() {
   return this.$store.state.roleMenu.includes("orderManage:view");
  },
  showBatch() {
   if (this.selectList.length) {
    let orderType = this.selectList[0].orderType;
    console.log(orderType, "----------------------orderType");
    let show = this.selectList.some((item) => item.orderType !== orderType);
    console.log(show, "--------show");
    return !show;
   }
   return false;
  },
  typeLists() {
   let obj = {};
   obj.orderTypeList = this.$store.state.orderTypeList;
   obj.orderStatusList = this.$store.state.orderStatusList;
   return obj;
  },
 },
 methods: {
  handleSelectionChange(val) {
   this.selectList = val;
  },
  // 根据值显示信息
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  openOrderView() {
   let routeUrl = this.$router.resolve({
    path: "/orderManage",
   });
   console.log(this.$router);
   console.log(routeUrl);
   window.open(routeUrl.href, "_blank");
  },
  // 搜索
  handleSearch() {
   this.pageNum = 1;
   this.getSearchData();
   this.getList();
  },
  // 重置
  reset() {
   this.pageNum = 1;
   //  this.searchForm = {};
   this.$refs.searchForm.resetFields();
   this.getSearchData();
   this.getList();
  },
  getSearchData() {
   this.listQuery = {};
   this.searchForm.hotelId = this.$store.state.hotelData.id;
   for (let key in this.searchForm) {
    if (key == "times") {
     if (this.searchForm.times) {
      this.listQuery.startTime = this.searchForm.times[0];
      this.listQuery.endTime = this.searchForm.times[1];
     } else {
      this.listQuery.startTime = this.listQuery.endTime = null;
     }
    } else if (this.searchForm[key] || this.searchForm[key] === 0) {
     this.listQuery[key] = this.searchForm[key];
    }
   }
  },
  // 导出
  exportExcel(i) {
   this.downLoading = true;
   if (this.loading) {
    if (i < 5) {
     setTimeout(() => {
      this.exportExcel(i);
     }, 1000);
    }
   } else {
    if (!this.tablist || this.tablist.length == 0) {
     this.$message.warning("未加载到数据");
     this.downLoading = false;
     return;
    }
    let tHeader = [];
    let filterVal = [];
    let execlTab = JSON.parse(JSON.stringify(this.execlTab));
    execlTab.forEach((v) => {
     if (v.label != "操作") {
      tHeader.push(v.label);
      filterVal.push(v.value);
     }
    });
    let list = this.getExeclData();
    let data = this.formatJson(filterVal, list);
    setTimeout(() => {
     export_json_to_excel(tHeader, data, `服务工单`);
     this.downLoading = false;
    }, 500);
   }
  },
  // d导出execl数据转换
  getExeclData() {
   let arr = new Array();
   this.tablist.forEach((v) => {
    let obj = Object.assign({}, v);
    // obj.duration = this.showLongTimes(v.duration);
    obj.orderType = this.showLabelText(
     v.orderType,
     this.typeLists.orderTypeList
    );
    obj.orderStatus = this.showLabelText(
     v.orderStatus,
     this.typeLists.orderStatusList
    );
    arr.push(obj);
   });
   return arr;
  },
  formatJson(filterVal, jsonData) {
   return jsonData.map((v) => filterVal.map((j) => v[j]));
  },
  // 列表数据
  getList() {
   this.loading = true;
   getServiceOrders(this.pageNum, this.pageSize, this.listQuery)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.tablist = res.data.data.records;
      this.total = res.data.data.total;
     } else {
      this.$message.warning(res.data.message);
     }
    })
    .catch((err) => {
     this.loading = false;
    });
  },
  handleSizeChange(val) {
   this.pageSize = val;
   this.getList();
  },
  handleCurrentChange(val) {
   this.pageNum = val;
   this.getList();
  },
  // // 返工
  // rework(item) {
  //  // this.$refs.assignTaskView.openSet(item,2);
  //  this.$confirm("该任务不合格，需返工?", "提示", {
  //   confirmButtonText: "确定",
  //   cancelButtonText: "取消",
  //   type: "warning",
  //  })
  //   .then(() => {
  //    this.reworkReq(item);
  //   })
  //   .catch(() => {});
  // },
  // // 返工
  // reworkReq(item) {
  //  let data = {};
  //  data.orderId = item.orderId;
  //  data.orderStatus = 3;
  //  data.employeeId = item.employeeId;
  //  console.log(data);
  //  updateOrderDetail(data)
  //   .then((res) => {
  //    console.log(res);
  //    this.loading = false;
  //    if (res.data.code == "000000") {
  //     this.$message.success("已通知处理人返工");
  //     this.showDialog = false;
  //     this.getList();
  //    } else {
  //     this.$message.error(res.data.message);
  //    }
  //   })
  //   .catch((err) => {
  //    console.log(err);
  //    this.loading = false;
  //   });
  // },
  // del(item) {
  //  // this.$refs.assignTaskView.openSet(item,2);
  //  this.$confirm("是否要删除此工单?", "提示", {
  //   confirmButtonText: "确定",
  //   cancelButtonText: "取消",
  //   type: "warning",
  //  })
  //   .then(() => {
  //    this.delReq(item.orderId);
  //   })
  //   .catch(() => {});
  // },
  // delReq(id) {
  //  deleteWorkOrder(id)
  //   .then((res) => {
  //    console.log(res);
  //    this.loading = false;
  //    if (res.data.code == "000000") {
  //     this.$message.success("删除成功");
  //     this.showDialog = false;
  //     this.getList();
  //    } else {
  //     this.$message.error(res.data.message);
  //    }
  //   })
  //   .catch((err) => {
  //    console.log(err);
  //    this.loading = false;
  //   });
  // },
  // //  验收
  // orderFinish(item) {
  //  this.$refs.assignTaskView.openSet(item, 3);
  // },
  //  分派
  assignTask(item) {
   this.$refs.assignTaskView.openSet([item], 1);
  },
  assignTaskAll() {
   this.$refs.assignTaskView.openSet(this.selectList, 1);
  },

  openDialog() {
   this.show = true;
   this.handleSearch();
  },
 },
};
</script>


<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");

.topTab {
 //  border-bottom: 3px solid rgb(80, 80, 80);
 padding-bottom: 20px;
 //  margin-bottom: 10px;
}

.roomStatusName {
 font-weight: 600;
}
.isSOS {
 color: #ff4d4d;
}
.isQingLi {
 color: #409eff;
}
.isMenCi {
 color: #e6a23c;
}
</style>