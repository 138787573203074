<template>
 <div class="messageDialog">
  <!-- 客房管理 -->
  <el-dialog title="消息提示" top="3%" :visible.sync="show" width="80%" class="roomManagementDialog" :close-on-click-modal="false">
   <div slot="title" class="title2 flexItem">
    <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
    <!-- <i class="el-icon-tickets iconColor"></i> -->
    <span class="ml">
     快捷功能管理
    </span>
   </div>
   <el-row :gutter="20">
    <el-col :span="9">
     <el-card class="box-card">
      <div slot="header" class="title2 flexItem">
       <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
       <!-- <i class="el-icon-tickets iconColor"></i> -->
       <span class="ml">
        已选功能
       </span>
      </div>
      <div class="topTab scroll2">
       <!-- <el-checkbox v-for="(item,index) in checkedMenus" :checked="item.checked" :label="item" :key="index"> -->
       <div v-for="(item,index) in saveList" :key="index" class="menuItem mr">

        <div class="flexCenter ">
         <div class="menuIcon flexCenter">
          <el-popover placement="top" width="300" v-model="item.visible">
           <div>
            <el-form ref="addform" label-width="50px" size="mini">
             <el-form-item label="排序" prop="sort">
              <el-input-number v-model="item.sort" :min="0" :step="1" :precision="0" label="排序" @blur="loadView"></el-input-number>
             </el-form-item>
             <el-form-item label="" label-width="0px">
              <el-radio-group v-model="item.color" size="mini" @change="changeColor">
               <el-radio-button v-for="(c,i) in colors" :key="i" class="colorItem" :label="c" border>
                <span class="colorItem2" :style="'background-color: '+c"></span>
               </el-radio-button>
              </el-radio-group>
              <!-- <el-checkbox-group v-model="item.color">
               <el-checkbox v-for="(c,i) in colors" :key="i" label="c" class="colorItem" ></el-checkbox>
              </el-checkbox-group> -->
             </el-form-item>
            </el-form>
           </div>

           <!-- <i class="el-icon-tickets muneMsg" slot="reference"></i> -->
           <el-button icon="el-icon-tickets" class="muneMsg" slot="reference" circle size="mini" type="menuIcon" :style="item.color?`background-color: ${item.color};`:''"></el-button>
          </el-popover>

         </div>
         <div class="ml saveName">
          <!-- <el-tooltip class="item" effect="dark" content="名字名字名字" placement="top-start"> -->
          <div class="name">
           {{item.menuName}}
          </div>
          <div class="nameIndex">{{item.sort?item.sort:0}}</div>
          <!-- </el-tooltip> -->

         </div>
         <div>
          <i class="el-icon-close deleteSize" @click="deleteItem(item,index)"></i>
         </div>

        </div>
       </div>

       <!-- </el-checkbox> -->

      </div>
      <div class="saveBTN">
       <!-- <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" layout="prev, pager, next, sizes, total, jumper" :total="total">
    </el-pagination> -->
       <!-- <div> -->
       <!-- <el-button size="mini">取消</el-button> -->
       <el-button size="mini" type="primary" @click="saveSubmit">保存</el-button>

       <!-- </div> -->
      </div>
     </el-card>
    </el-col>
    <el-col :span="15">
     <el-card class="box-card">
      <div slot="header" class="title2 flexItem">
       <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
       <span class="ml">
        系统功能
       </span>
      </div>
      <div class="topTab2 scroll2">
       <el-checkbox-group v-model="checkedMenus" @change="changeItem">
        <el-checkbox v-for="(item,index) in menuList" :checked="item.checked" :label="item.menuId" :key="index" class="mr">
         <div class="flexCenter ">
          <div class="menuIcon menuIcon2 flexCenter">
           <i class="el-icon-tickets "></i>
          </div>
          <div class="ml menuName">
           <el-tooltip class="item" :disabled="!(item.menuName&&item.menuName.length>5)" effect="dark" :content="item.menuName" placement="top-start">
            <span>
             {{item.menuName}}

            </span>
           </el-tooltip>

          </div>

         </div>

        </el-checkbox>
       </el-checkbox-group>

       <!-- <div class="menuItem" style="width:300px" v-for="(icon,iconIndex) in icons" :key="iconIndex">
          <i class="iconfont" :class="icon" ></i>
          <span>{{icon}}</span>
       </div> -->

      </div>
     </el-card>
    </el-col>

   </el-row>
   <div>
   </div>

  </el-dialog>

 </div>
</template>
<script>
import { getAllMenu, saveMyMenus } from "@/api";
export default {
 data() {
  return {
   show: false,
   checkedMenus: [],
   changeMenus: [],
   menuList: [],
   colors: ["#52D5BA", "#FFAF2A", "#0890FE", "#FF4267", "#6d60ed"],
  };
 },
 computed: {
  saveList() {
   return this.changeMenus.sort((a, b) => {
    //  let bIndex = b.sort ? b.sort : 0;
    //  let aIndex = a.sort ? a.sort : 0;
    return a.sort - b.sort;
   });
  },
 },
 created() {
  this.getMenus();
 },
 methods: {
  loadView() {
   this.$forceUpdate();
  },
  openDialog(list) {
   this.changeMenus = list;
   let checkedMenus = [];
   list.forEach((item) => {
    checkedMenus.push(item.menuId);
   });
   this.checkedMenus = checkedMenus;
   this.show = true;
  },
  deleteItem(item, index) {
   this.changeMenus.splice(index, 1);
   let i = this.checkedMenus.findIndex((item2) => {
    return item2 == item.menuId;
   });
   if (i > -1) this.checkedMenus.splice(i, 1);
   this.$forceUpdate();
  },
  changeColor() {
   this.$forceUpdate();
  },
  changeItem() {
   this.getChangeMunes();
   this.$forceUpdate();
  },
  getChangeMunes() {
   if (this.checkedMenus && this.checkedMenus.length) {
    this.checkedMenus.forEach((item) => {
     let i = this.changeMenus.findIndex((item2) => { return item2.menuId == item});
     if (i == -1) {
      let menuIndex=this.menuList.findIndex(itemMenu=>{  return item==itemMenu.menuId })
      let menuData=this.menuList[menuIndex]
      this.changeMenus.push({
       ...menuData,
       sort: this.changeMenus.length,
       color: "#0094ff",
      });
     }
    });
     this.changeMenus.forEach((item,index) => {
     let has = this.checkedMenus.some(item2 => {return item2 == item.menuId});
   //   console.log(has, "-----------",item);
     if (!has)  this.changeMenus.splice(index, 1);
    });
   } else {
    this.changeMenus = [];
   }
  },
  getMenus() {
   getAllMenu().then((res) => {
    this.menuList = res.data.data;
   });
  },
  saveSubmit() {
   // console.log("保存", this.saveList);
   let list = [];
   this.saveList.forEach((item) => {
    list.push({
     menuId: item.menuId,
     sort: item.sort,
     color: item.color,
     menuName: item.menuName,
     path: item.path,
    });
   });
   let data = {
    list,
   };
   this.saveMenusReq(data);
  },
  saveMenusReq(data) {
   // console.log(data);
   saveMyMenus(data).then((res) => {
   //  console.log(res);
    if (res.data.code == "000000") {
     this.$message.success("保存成功");
     this.$parent.getList();
     setTimeout(() => {
      this.show=false
     }, 1500);
    }else{
       this.$message.success(res.message);
    }
   });
  },
 },
};
</script>


<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");
.iconfont {
 font-size: 28px;
}
.topTab {
 height: 500px;
 overflow-y: auto;
}
.topTab2 {
 height: 540px;
 overflow-y: auto;
}
.saveBTN {
 padding-top: 10px;
 height: 30px;
 text-align: right;
}

.menuIcon {
 //  padding: 0.3vh 0.2vw;
 width: 1.3vw;
 min-width: 1.3vw;
 height: 1.3vw;
 //  background-color: #FFAF2A;
 border-radius: 6px;
 i {
  font-size: 12px;
  color: #fff;
 }
}
.menuIcon2 {
 background-color: #ffaf2a;
}
.is-checked .menuIcon2 {
 background-color: #0094ff;
}
.muneMsg {
 font-size: 12px;
 color: #fff;
 //  background-color: #0094ff;
 border: none !important;
}
.colorItem,
.colorItem2 {
 display: inline-block;
 width: 30px;
 height: 30px;
 //  background-color: aqua;

 border-radius: 5px;
}
.colorItem + .colorItem {
 margin-left: 12px;
}

.menuItem {
 width: calc(33.33% - 12px);
 height: 5vh;
 line-height: 5vh;
 cursor: pointer;
 //  font-size: 0.8vw;
 //  white-space: nowrap;
 display: inline-block;
}
.menuName {
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
 //  width: 60%;
 width: 100px;
 height: 5vh;
 line-height: 5vh;
 //  text-align: left;
}
.saveName {
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
 line-height: 2vh;
 width: 60%;
 .name {
  font-size: 1.3vh;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
 }
 .nameIndex {
  font-size: 1vh;
 }
}
</style>