
<template>
 <div class="bench">
  <Headers></Headers>
  <el-row :gutter="20">
   <el-col :span="5">
    <div class=" theRoomTotal ">
     <roomTotal></roomTotal>
    </div>
    <div class=" theTop  mt20 ">
     <TopPage></TopPage>
    </div>

    <div class="mt20 placeOrder">
     <placeOrder ref="placeOrder"></placeOrder>
    </div>
   </el-col>
   <el-col :span="19">
    <el-row :gutter="20" class="row1">
     <el-col :span="10">
      <tab4></tab4>
     </el-col>
     <el-col :span="7">
      <menuSet></menuSet>
     </el-col>
     <el-col :span="7">
      <workSpeed></workSpeed>
     </el-col>
    </el-row>
    <el-row :gutter="20" class="row2 mt20">
     <el-col :span="9">
      <workTotal></workTotal>
     </el-col>
     <el-col :span="8">
      <checkIns></checkIns>
     </el-col>
     <el-col :span="7">
      <loss></loss>
     </el-col>
    </el-row>

   </el-col>

  </el-row>
 </div>
</template>

<script>
import Headers from "../components/header.vue";
import TopPage from "../components/topPage.vue";
import roomTotal from "../components/roomTotal.vue";
import placeOrder from "../components/placeOrder.vue";
import tab4 from "../components/tab4/index.vue";
import menuSet from "../components/menus/index.vue";
import workSpeed from "../components/workSpeed/index.vue";
import workTotal from "../components/workTotal/index.vue";
import checkIns from "../components/checkIn/index.vue";
import loss from "../components/loss.vue";

export default {
 // name: "Home",
 components: {
  Headers,
  TopPage,
  roomTotal,
  placeOrder,
  tab4,
  menuSet,
  workSpeed,
  workTotal,
  checkIns,
  loss,
 },

 data() {
  return {
   hotelId: null,
  };
 },
 created() {
  //   console.log(this.$route);
//   this.getHotelId()
 },

 methods: {
//   getHotelId(i = 0) {
//    let hotelId = this.$store.state.hotelData.id;
//    if (hotelId) {
//     this.hotelId = hotelId;
//     this.getList();
//    } else {
//     i++;
//     if (i < 10) {
//      setTimeout(() => {
//       this.getHotelId(i);
//      }, 500);
//     }
//    }
//   },
//   getList(){

//   }
 },
};
</script>

<style lang="scss">
.mt20 {
 margin-top: 2vh;
}
</style>
<style lang="scss" scoped>
@import url("../../../../assets/benchCss/base.scss");
.bench {
 padding: 0 10px;
}
.row1 {
 height: 57vh;
 .el-col {
  height: 100%;
 }
}
.row2 {
 height: 32vh;
 .el-col {
  height: 100%;
 }
}
.theTop {
 width: 100%;
 height: 35vh;
}
.theRoomTotal {
 width: 100%;
 height: 20vh;
}
.placeOrder {
 width: 100%;
 height: 32vh;
}
</style>
