<template>
 <div class="demo  theBar borderBar ">
  <div class="title flexSb">
   <div>
    下单渠道

   </div>
   <!-- <div>
          <el-button size="mini"  circle icon="el-icon-search"></el-button>
      </div> -->
   <div class="openIcon flexCenter">
    <i class="el-icon-search iconColor" @click="openDetail"></i>
   </div>
  </div>
  <div class="cbenchContent">
   <div id="echartOrder" class="chartBar"></div>
  </div>
 </div>
</template>
<script>
import CountTo from "vue-count-to";
// import { GET_SEVEN_PMS } from "@/api";
// import { isPhone } from "@/api/common";

export default {
 components: {
  CountTo,
 },
 data() {
  return {
   placeOrderName: [
    "美团",
    "去哪儿",
    "艺龙",
    "飞猪旅行",
    "携程",
    "途家",
    "蚂蚁短租",
   ],
   placeOrder: [
    {
     name: "艺龙",
     value: 356,
    },
    {
     name: "飞猪旅行",
     value: 288,
    },
    {
     name: "携程",
     value: 72,
    },
    {
     name: "美团",
     value: 1099,
    },
    {
     name: "途家",
     value: 23,
    },

    {
     name: "去哪儿",
     value: 619,
    },
    {
     name: "蚂蚁短租",
     value: 10,
    },
   ],
  };
 },
 computed: {
  color() {
   return this.$store.state.color2;
  },
 },
 mounted() {
  this.echartOrder = this.$echarts.init(document.getElementById("echartOrder"));
  window.addEventListener("resize", () => {
   this.echartOrder.resize();
  });
  this.getOrderType();
  this.placeOrderInter = null;
  this.placeOrderInter = setInterval(() => {
   this.getOrderType();
  }, 60000);
 },

 beforeDestroy() {
  clearInterval(this.placeOrderInter);
 },
 methods: {
  openDetail() {
   this.$message.warning("下单渠道详情暂未开放");
  },
  getOptions(data) {
   return {
    tooltip: {
     trigger: "item",
     textStyle: {
      color: "#fff",
     },
     // {a} <br/>{b} : {c} ({d}%)
     formatter: "{b} : {c} ({d}%)",
    },
    color: this.color,
    legend: {
     top: "center",
     orient: "vertical",
      icon:"path://M512 960C264.576 960 64 759.424 64 512S264.576 64 512 64s448 200.576 448 448-200.576 448-448 448z m0-268.8a179.2 179.2 0 1 0 0-358.4 179.2 179.2 0 0 0 0 358.4z",
   
     right: "5%",
     textStyle: {
      color: "#000",
     },
     data: this.placeOrderName,
    },
    series: [
     {
      name: "下单渠道",
      type: "pie",
      radius: ["40%", "60%"],
      center: ["35%", "50%"],
      label: {
       show: false,
      },
      data: this.placeOrder,
      emphasis: {
       itemStyle: {
        shadowBlur: 10,
        shadowOffsetX: 0,
        shadowColor: "rgba(0, 0, 0, 0.5)",
       },
      },
     },
    ],
   };
  },
  async getOrderType(data = {}) {
   this.initLine();
  },
  initLine() {
   this.echartOrder.setOption(this.getOptions(this.sevenPms));
  },
  resize() {
   this.echartOrder.resize();
  },
 },
};
</script>
<style lang="scss" scoped>
@import url("../../../../assets/benchCss/base.scss");
.demo {
 width: 100%;
 height: 100%;
 .cbenchContent {
  width: 100%;
  height: calc(100% - 5vh);
 }
}
</style>