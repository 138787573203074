var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messageDialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "roomManagementDialog",
          attrs: {
            title: "消息提示",
            top: "3%",
            visible: _vm.show,
            width: "80%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "title2 flexItem",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("img", {
                staticClass: "titleImg",
                attrs: {
                  src: require("../../../../../assets/benchCss/img/icon-list.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "ml" }, [
                _vm._v("\n    快捷功能管理\n   "),
              ]),
            ]
          ),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 9 } },
                [
                  _c("el-card", { staticClass: "box-card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title2 flexItem",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("img", {
                          staticClass: "titleImg",
                          attrs: {
                            src: require("../../../../../assets/benchCss/img/icon-list.png"),
                            alt: "",
                          },
                        }),
                        _c("span", { staticClass: "ml" }, [
                          _vm._v("\n       已选功能\n      "),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "topTab scroll2" },
                      _vm._l(_vm.saveList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "menuItem mr" },
                          [
                            _c("div", { staticClass: "flexCenter " }, [
                              _c(
                                "div",
                                { staticClass: "menuIcon flexCenter" },
                                [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: { placement: "top", width: "300" },
                                      model: {
                                        value: item.visible,
                                        callback: function ($$v) {
                                          _vm.$set(item, "visible", $$v)
                                        },
                                        expression: "item.visible",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form",
                                            {
                                              ref: "addform",
                                              refInFor: true,
                                              attrs: {
                                                "label-width": "50px",
                                                size: "mini",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "排序",
                                                    prop: "sort",
                                                  },
                                                },
                                                [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      min: 0,
                                                      step: 1,
                                                      precision: 0,
                                                      label: "排序",
                                                    },
                                                    on: { blur: _vm.loadView },
                                                    model: {
                                                      value: item.sort,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "sort",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.sort",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-form-item",
                                                {
                                                  attrs: {
                                                    label: "",
                                                    "label-width": "0px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-radio-group",
                                                    {
                                                      attrs: { size: "mini" },
                                                      on: {
                                                        change: _vm.changeColor,
                                                      },
                                                      model: {
                                                        value: item.color,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "color",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.color",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.colors,
                                                      function (c, i) {
                                                        return _c(
                                                          "el-radio-button",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "colorItem",
                                                            attrs: {
                                                              label: c,
                                                              border: "",
                                                            },
                                                          },
                                                          [
                                                            _c("span", {
                                                              staticClass:
                                                                "colorItem2",
                                                              style:
                                                                "background-color: " +
                                                                c,
                                                            }),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("el-button", {
                                        staticClass: "muneMsg",
                                        style: item.color
                                          ? "background-color: " +
                                            item.color +
                                            ";"
                                          : "",
                                        attrs: {
                                          slot: "reference",
                                          icon: "el-icon-tickets",
                                          circle: "",
                                          size: "mini",
                                          type: "menuIcon",
                                        },
                                        slot: "reference",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "ml saveName" }, [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(item.menuName) +
                                      "\n         "
                                  ),
                                ]),
                                _c("div", { staticClass: "nameIndex" }, [
                                  _vm._v(_vm._s(item.sort ? item.sort : 0)),
                                ]),
                              ]),
                              _c("div", [
                                _c("i", {
                                  staticClass: "el-icon-close deleteSize",
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteItem(item, index)
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "saveBTN" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.saveSubmit },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 15 } },
                [
                  _c("el-card", { staticClass: "box-card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title2 flexItem",
                        attrs: { slot: "header" },
                        slot: "header",
                      },
                      [
                        _c("img", {
                          staticClass: "titleImg",
                          attrs: {
                            src: require("../../../../../assets/benchCss/img/icon-list.png"),
                            alt: "",
                          },
                        }),
                        _c("span", { staticClass: "ml" }, [
                          _vm._v("\n       系统功能\n      "),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "topTab2 scroll2" },
                      [
                        _c(
                          "el-checkbox-group",
                          {
                            on: { change: _vm.changeItem },
                            model: {
                              value: _vm.checkedMenus,
                              callback: function ($$v) {
                                _vm.checkedMenus = $$v
                              },
                              expression: "checkedMenus",
                            },
                          },
                          _vm._l(_vm.menuList, function (item, index) {
                            return _c(
                              "el-checkbox",
                              {
                                key: index,
                                staticClass: "mr",
                                attrs: {
                                  checked: item.checked,
                                  label: item.menuId,
                                },
                              },
                              [
                                _c("div", { staticClass: "flexCenter " }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "menuIcon menuIcon2 flexCenter",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-tickets ",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "ml menuName" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            disabled: !(
                                              item.menuName &&
                                              item.menuName.length > 5
                                            ),
                                            effect: "dark",
                                            content: item.menuName,
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "\n            " +
                                                _vm._s(item.menuName) +
                                                "\n\n           "
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("div"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }