var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "benchPage   theBar borderBar" }, [
    _c("div", { staticClass: "title " }, [
      _vm._v("\n  " + _vm._s(_vm.hotelData.name) + "\n\n  "),
    ]),
    _c("div", { staticClass: "flexItem cbenchContent" }, [
      _c("div", { staticClass: "topImg flexCenter column" }, [
        _c("div", { staticClass: "indexNum" }, [
          _vm._v("\n    " + _vm._s(_vm.total) + "\n   "),
        ]),
        _c("div", { staticClass: "content2" }, [_vm._v("房间数")]),
      ]),
      _c("div", { staticClass: "chareImg", attrs: { id: "roomTotal" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }