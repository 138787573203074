<template>
 <div class="benchHeader">
  <div class="benchName flexCenter">
   <div class="LogoImg">
    <img src="../../../../assets/img/logo2.png" alt="">
   </div>
   <div>
    <div class="benchType">
     店长工作台
    </div>
    <!-- <div class="hotelName1">
     欢朋亚运大道店
    </div> -->
   </div>
   <div class="benchName-right">
    <div class="tq">
     <!-- 天气 21度  -->
     {{theDay}}
    </div>
    <div class="times">
     {{nowTime}}
     <!-- 2021-02-05 08:30 -->
    </div>
   </div>
  </div>
  <div class="bench-right flexCenter">
   <!-- <div class="bench-user-con"> -->
   <!-- 全屏显示 -->
   <div class="hotelBar flexCenter" @click="openHotel">
    <i class="el-icon-info"></i>
    <div class="hotelName2">
     {{hotelData.name}}
    </div>
   </div>
   <div class="theRing m30">
    <i class="el-icon-bell" v-if="1"></i>
    <i v-else class="el-icon-close-notification"></i>
   </div>
   <el-dropdown class="user-name m30" trigger="click" @command="handleCommand">
    <i class="el-icon-user"></i>
    <el-dropdown-menu slot="dropdown">
     <el-button @click="resetPassword" style="border:0">修改密码</el-button>
     <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
    </el-dropdown-menu>
   </el-dropdown>
   <!-- </div> -->
  </div>
  <el-dialog :close-on-click-modal="false" title="酒店选择" :visible.sync="changeHotel" width="500px">
   <el-form label-position="right" label-width="80px" :rules="myHotel" ref="hotelForm" :model="hotelForm">
    <el-form-item label="品牌" prop="brandId">
     <el-select v-model="hotelForm.brandId" @change="selectOne" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in brandSelectData" :key="index" :label="item.brandName" :value="item.id"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="门店" prop="hotelId">
     <el-select v-model="hotelForm.hotelId" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in hotelSelectData" :key="index" :label="item.name" :value="item.id"></el-option>
     </el-select>
    </el-form-item>
   </el-form>
   <div slot="footer">
    <el-button @click="changeHotel=false">取 消</el-button>
    <el-button type="primary" @click="saveHotel('hotelForm')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
  <!-- 重置密码的弹框 -->
  <el-dialog :close-on-click-modal="false" title="重置密码" :visible.sync="dialogFormVisible3" width="500px">
   <el-form label-position="right" label-width="80px" :rules="myrules" :ref="passwordData" :model="passwordData">
    <el-row :gutter="10">
     <el-col :span="24">
      <el-form-item label="账号">
       <el-input placeholder="请输入内容" v-model="passwordData.username" clearable disabled class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="真实姓名">
       <el-input placeholder="请输入内容" v-model="passwordData.fullName" clearable disabled class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="密码" prop="password">
       <el-input placeholder="请输入内容" v-model="passwordData.password" show-password class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="确认密码" prop="password2">
       <el-input placeholder="请再次输入密码" v-model="passwordData.password2" show-password class="my-input"></el-input>
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div slot="footer">
    <el-button @click="abrogateDassword">取 消</el-button>
    <el-button type="primary" @click="confirmDassword(passwordData)" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import { editpassword, getBrandSelect, getHotelSelect ,GET_TIAN_QI,GET_HOTEL_NAME} from "@/api";
import moment from "moment";

export default {
 data() {
  /* 密码自定义校验规则 */
  let Password = (rule, value, callback) => {
   if (value == "") {
    callback(new Error("密码不能为空"));
   } else {
    let passwordReg = /^(?![^a-zA-Z]+$)(?!\D+$).{6,10}$/;
    if (passwordReg.test(value)) {
     callback();
    } else {
     callback(new Error("至少包含字母和数字，长度 6-10位"));
    }
   }
  };

  var validatePass2 = (rule, value, callback) => {
   // console.log(value)
   if (value === "") {
    callback(new Error("请再次输入密码"));
   } else if (value !== this.passwordData.password) {
    callback(new Error("两次输入密码不一致!"));
   } else {
    callback();
   }
  };
  return {
   hotelForm: {
    brandId: null,
    hotelId: null,
    hotelName: null,
   },
   nowTime: null,
   theDay: null,
   loading: false,
   name: "linxin",
   // 重置密码数据
   dialogFormVisible3: null,
   passwordData: {
    username: null,
    fullName: null,
    password: null,
    password2: null,
   },
   changeHotel: false,
   myHotel: {
    brandId: [
     { required: true, message: "Password", trigger: ["blur", "change"] },
    ],
    hotelId: [
     { required: true, message: "Password", trigger: ["blur", "change"] },
    ],
   },
   myrules: {
    password: [
     { required: true, validator: Password, trigger: ["blur", "change"] },
    ],
    password2: [
     { required: true, validator: validatePass2, trigger: ["blur", "change"] },
    ],
   },
   brandSelectData: null,
   hotelSelectData: null,
   LocationData: null,
  };
 },
 computed: {
  username() {
   let username = sessionStorage.getItem("username");
   username = username.replace(/\"/g, "");
   return username ? username : this.name;
  },
  hotelData() {
   return this.$store.state.hotelData;
  },
 },
 mounted() {
  this.getTime();
  this.timer = setInterval(() => {
   this.getTime();
  }, 1000);
 },
 beforeDestroy() {
  if (this.timer) {
   clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
  }
 },
 created() {
  this.initialize();
  this.getLocation()
  this.getName()
 },
 methods: {
  getLocation() {
   var _this = this;
   if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
     //locationSuccess 获取成功的话
     function (position) {
       console.log(position);
      _this.LocationData = position.coords;
      _this.tiqnqi()
     },
     //locationError  获取失败的话
     function (error) {
       console.log(error,'jjkdjjdjdjd',error.code);
      // var errorType = ['您拒绝共享位置信息', '获取不到位置信息', '获取位置信息超时'];
      // alert(errorType[error.code - 1]);
     }
    );
   }
  },
  getName(){
    GET_HOTEL_NAME(this.hotelData.id).then(res=>{
      console.log(res.data.data);
      if(res.data.code=='000000'){
        let data=res.data.data
         this.$store.commit('SAVE_HOTEL',data)
      }
    })
  },
  tiqnqi(){
    if(this.LocationData){

      let location=`${this.LocationData.latitude}:${this.LocationData.longitude}`
      GET_TIAN_QI({location}).then(res=>{
        console.log(res);
      })
    }
  },
  saveHotel(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     console.log(valid);
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },

  /* 初始化下拉框 */
  // 获取品牌下拉框 -- 1
  initialize() {
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
    }
   });
  },
  // 获取门店下拉框 -- 2
  selectOne(id) {
   this.hotelForm.hotelId = null;
   getHotelSelect(id).then((res) => {
    this.hotelSelectData = res.data.data;
    if (!res.data.data) {
     this.$message({
      message: "该品牌下没有门店数据",
      type: "warning",
     });
    }
   });
  },
  openHotel() {
   //  this.changeHotel=true
  },
  getTime() {
   this.nowTime = moment().format("yyyy年MM月Do HH:mm:ss");
   this.theDay = moment().format("dddd");
  },
  // 重置密码按钮
  resetPassword() {
   var page = JSON.parse(sessionStorage.getItem("user"));
   this.passwordData.username = page.username;
   this.passwordData.fullName = page.fullName;
   this.dialogFormVisible3 = true;
  },
  confirmDassword(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     editpassword(this.passwordData).then((res) => {
      this.loading = false;
      if (res.data.code == "000000") {
       this.dialogFormVisible3 = false;
       this.$alert("密码已修改，请重新登录", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
         this.$store.commit("SET_TOKEN");
         sessionStorage.clear();
         location.reload();
         this.$destroy();
        },
       });
      }
     });
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  abrogateDassword() {
   this.dialogFormVisible3 = false;
  },
  // 用户名下拉菜单选择事件
  handleCommand(command) {
   if (command == "loginout") {
    this.$store.commit("SET_TOKEN");
    sessionStorage.clear();
    this.$destroy();
    location.reload();
   }
  },
 },
};
</script>
<style scoped lang="scss">
.LogoImg {
 height: 60%;
 margin-right: 0.6vw;
 img {
  height: 100%;
 }
}
.benchHeader {
 height: 7vh;
 //  box-sizing: border-box;
 //  color: saddlebrown;
 display: flex;
 align-items: center;
 justify-content: space-between;
}

.benchName {
 height: 100%;
 cursor: pointer;
 //  display: flex;
 //  align-items: center;
 .benchType {
  font-size: 2.5vh;
  line-height: 3vh;
  font-weight: bold;
  color: #333;
  letter-spacing: 3px;
 }
 .hotelName1 {
  color: #666;
  padding: 5px 0;
  font-size: 1vh;
  letter-spacing: 3px;
 }
 .benchName-right {
  margin-left: 30px;
 }
 .tq {
  font-size: 1vh;
  font-weight: bold;
  color: #282d36;
  padding: 2px 0;
 }
 .times {
  font-size: 1.2vh;
  height: 1.4vh;
  color: #666;
  // padding: 2px 0;
 }
}
.hotelBar {
 //  width: 10vw;
 padding: 0 1vw;
 height: 5vh;
 background: rgba(98, 60, 231, 0.2);
 border-radius: 10px 10px 10px 10px;
 color: #623ce7;
 cursor: pointer;
 i {
  font-size: 2vh;
 }
 .hotelName2 {
  margin-left: 12px;
  font-size: 1.2vh;
 }
}
.m30 {
 margin-left: 30px;
}
.theRing {
 font-size: 20px;
 cursor: pointer;
}
.user-name {
 font-size: 20px;
 color: #000;
 cursor: pointer;
}
</style>
