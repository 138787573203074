
<template>
 <div class="benchPage  theBar borderBar">
  <div class="topIndex flexItem">
   <div class="topImg">
    <img v-if="theTop.monthRanking==1" src="../../../../assets/benchCss/img/Property 1=1.png" alt="">
    <img v-else-if="theTop.monthRanking==2" src="../../../../assets/benchCss/img/Property 1=2.png" alt="">
    <img v-else-if="theTop.monthRanking==3" src="../../../../assets/benchCss/img/Property 1=3.png" alt="">
    <img v-else src="../../../../assets/benchCss/img/Property 1=4.png" alt="">
   </div>
   <div class="TopContent">
    <div class="theIndex">
     <span>第</span>
     <span class="indexNum">{{theTop.monthRanking}}</span>
     <span>名</span>
    </div>
    <div class="content2">{{theTop.name}}</div>
   </div>
  </div>
  <div class="topheader tabRow  flexItem" v-if="list&&list.length">
   <div class="flex2">
    集团排行榜
   </div>
   <div class="flex1">
    本月
   </div>
   <div class="flex1">
    七月累积
   </div>
  </div>
  <div class="topTab scroll">
   <div class="tabRow  flexItem" v-for="(item,index) in list" :key="index">
    <div class="flex2">
     {{item.name}}
    </div>
    <div class="flex1">
     {{item.monthRanking}}
    </div>
    <div class="flex1">
     {{item.sevenMonthRanking}}
    </div>
   </div>

  </div>
 </div>
</template>

<script>
import {
  getTopIndex
} from "@/api"
export default {
 data() {
  return {
   list:[]
  };
 },

 computed: {
  theTop(){
    if(this.list&&this.list.length){
      return this.list[0]
    }else{
      return {}
    }
  },  
  hotelData() {
   return this.$store.state.hotelData;
  },
 },
 created() {
   this.getTopIndexReq()
 },

 methods: {
   getTopIndexReq(){
     let data={
       hotelId:this.hotelData.id,
       brandId:this.hotelData.brandId
     }
    console.log(data);
     getTopIndex(data).then((res) => {
       this.list=res.data.data
     })
   }
 },
};
</script>

<style lang="scss" scoped>
@import url("../../../../assets/benchCss/base.scss");
.benchPage {
 width: 100%;
 height: 100%;
 .topTab {
  height: calc(100% - 19vh);
  overflow-y: auto;
 }
}
.topIndex {
 padding: 1.5vh 2vw;
}
.topImg {
 //  width: 112px;
 //  min-width: 112px;
 //  height: 112px;
 width: 11vh;
 min-width: 11vh;
 height: 11vh;
 //  background-color: rgb(226, 218, 218);
 img {
  max-height: 100%;
  max-width: 100%;
 }
}
.TopContent {
 margin-left: 20px;
 // flex: 1;
 width: 150px;
 text-align: center;
}
.theIndex {
 font-size: 1.5vw;
 color: #f69e1e;
 overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;
 .indexNum {
  font-size: 2.5vw;
  font-weight: bold;
 }
}

.topheader.tabRow {
 background: #fc6b21 !important;
 font-weight: bold;
 color: #fff;
 font-size: 1.4vh;
}
</style>
