
<template>
 <div class="benchPage ">
  <!-- theBar -->
  <div class="topTabs flexItem">

   <div :class="{activeType:0==acIndex}" class="tabBar tabShadow " @click="changeIndex(0)">
    <div class="tabName">报警</div>
    <div class="TabNum">
      {{msgList.length}}
      <!-- {{SOSList|returnWarningNum(offLineList)}} -->
    </div>
    <div class="content2">SOS 门牌 门磁</div>

    <div class="iconRing" @click="closeTips" v-if="SOSList.length">
     <i v-if="tip " class=" el-icon-message-solid"></i>
     <i v-else class=" el-icon-close-notification"></i>
    </div>

   </div>
   <div :class="{activeType:1==acIndex}" class="tabBar tabShadow " @click="changeIndex(1)">
    <div class="tabName">服务</div>
    <div class="TabNum">{{serviceOrderCount}}</div>
    <div class="content2">服务工单</div>

   </div>
   <div :class="{activeType:2==acIndex}" class="tabBar tabShadow " @click="changeIndex(2)">
    <div class="tabName">异常</div>
    <div class="TabNum">{{eqCount}}</div>
    <div class="content2">设备异常</div>

   </div>
   <!-- <div :class="{activeType:3==acIndex}" class="tabBar tabShadow " @click="changeIndex(3)">
    <div class="tabName">故障</div>
    <div class="TabNum">{{JkCount}}</div>
    <div class="content2">故障通知</div>
   </div> -->

  </div>

  <!-- closeTips -->
  <div class="mt20 theBar theTabRows ">
   <div class="title flexSb">
    <div class="flexItem">
     <img class="titleImg" src="../../../../../assets/benchCss/img/icon-list.png" alt="">
     <!-- <i class="el-icon-tickets iconColor"></i> -->
     <span class="ml">
      {{tabName}}
     </span>
    </div>
    <div class="openIcon" @click="openDetail">
     <i class="el-icon-search iconColor"></i>
    </div>
   </div>
   <div class=" tabRowsBar  scroll2">
    <el-table :data="tablist" :show-header="false" class="managerTablist">
     <el-table-column width="55">
      <template>
       <div class="flexCenter">
        <img src="../../../../../assets/benchCss/img/icon-row.png" alt="" width="20">
       </div>
      </template>
     </el-table-column>
     <el-table-column v-for="(item,i) in execlTab" :key="i" :width="item.width" :prop="item.value" :align="item.type=='operation'?'center':''" :label="item.label" show-overflow-tooltip>
      <template slot-scope="scope">
       <div v-if="item.type=='operation'">
        <el-button type="primary" size="mini" @click="assignTask(scope.row)">工作轨迹</el-button>
       </div>
       <span v-if="item.solt_TEXT">
        {{showLabelText(scope.row[item.value],typeLists[item.solt_TEXT])}}
       </span>
       <span v-else-if="item.value=='status'">
        <span class="roomStatusName" :class="{
                            isSOS: scope.row.status == 'SOS',
                            isQingLi: scope.row.status == '清理操作',
                            isMenCi: scope.row.status == '门磁报警',
                            isOff:scope.row.status == '门牌离线'
                          }">{{ scope.row.status }}</span>
       </span>
       <span v-else-if="item.value=='longTimes'">
        <i class="el-icon-time"></i> {{showLongTimes(scope.row.startTime,scope.row.endTime)}}
       </span>
       <span v-else-if="item.value=='roomNumber'">
        {{scope.row[item.value]}}<span v-if="scope.row[item.value]">房</span>
       </span>
       <span v-else>
        {{scope.row[item.value]}}
       </span>
      </template>
     </el-table-column>
    </el-table>
   </div>
  </div>
  <messageBar ref="msgBar" :tablist="msgList" :execlTab="msgTabs"></messageBar>
  <orderList ref="orders"></orderList>
  <eqLog ref="eqLog"></eqLog>
  <gzjk ref="gzjk"></gzjk>
 </div>
</template>

<script>
import messageBar from "./msgDiaolog.vue";
import orderList from "./orderList.vue";
import eqLog from "./eqLog.vue";
import gzjk from "./gzjk.vue";
import {
 getHotelWarning,
 getServiceOrderCount,
 getServiceOrders,
 warningList,
 getDeviceFaultAlarmList,
} from "@/api";
import {
 getLongDateTime,
 secondGetLongTime,
 showLabel,
 getDay,
} from "@/api/common.js";
const typeLists = {
 abnormals: [
  {
   label: "门铃异响",
   value: 0,
  },
  {
   label: "设备离线",
   value: 1,
  },
  {
   label: "自动重启",
   value: 2,
  },
  {
   label: "UUID重复",
   value: 3,
  },
  {
   label: "房间号错误",
   value: 4,
  },
 ],
 abnormalStatus: [
  {
   label: "持续中",
   value: 0,
  },
  {
   label: "已结束",
   value: 1,
  },
 ],
 sbstatus: [
  {
   label: "在线",
   value: "on",
  },
  {
   label: "离线",
   value: "off",
  },
 ],
 // ckList:this.ckList
};
export default {
 components: {
  messageBar,
  orderList,
  eqLog,
  gzjk,
 },
 filters:{
   returnWarningNum(n1,n2){
     return n1.length+n2.length
   }
 },
 data() {
  return {
   acIndex: 0,
   defaultTime: ["00:00:00", "23:59:59"],

   msgTabs: [
    {
     label: "建筑名称",
     value: "buildingName",
     //  width:120
    },
    {
     label: "楼层",
     value: "floorName",
    },
    {
     label: "房间号",
     value: "roomNumber",
    },
    {
     label: "消息类型",
     value: "status",
    },
   ],
   serverTabs: [
    {
     label: "门店",
     value: "hotelName",
    },
    // {
    //  label: "楼层",
    //  value: "floorName",
    // },
    {
     label: "房间号",
     value: "roomNumber",
     width: 80,
    },
    {
     label: "工单类型",
     value: "orderType",
     solt_TEXT: "orderTypeList",
     width: 80,
    },
    {
     label: "描述",
     value: "remark",
    },
   ],
   abnormalTabs: [
    // {
    //  label: "建筑名称",
    //  value: "buildingName",
    //  //  width:120
    // },
    // {
    //  label: "楼层",
    //  value: "floorName",
    // },
    {
     label: "房间号",
     value: "roomNumber",
    },
    {
     label: "版本号",
     value: "version",
     // width: 60,
    },
    {
     label: "插卡状态",
     value: "identity",
     solt_TEXT: "ckList",
     //  width: 100,
    },
    {
     label: "异常类型",
     value: "warningType",
     solt_TEXT: "abnormals",
     width: 80,
    },
    {
     label: "持续时长",
     value: "longTimes",
     width: 180,
    },
   ],
   faultTabs: [
    {
     label: "建筑名称",
     value: "buildingName",
     //  width:120
    },
    {
     label: "楼层",
     value: "floorName",
    },
    {
     label: "房间号",
     value: "roomNumber",
    },
    {
     label: "故障类型",
     value: "typeName",
    },
    {
     label: "时间",
     value: "findTime",
     width: 150,
    },
   ],
  
   msgList: [],
   SOSList: [],
   offLineList:[],
   tip: true, // 语音提示打开
   serviceOrderCount: 0,
   eqList: [],
   eqCount: 0,
   serviceList: [],
   JkList: [],
   JkCount: 0,
   today:[]
  };
 },
 computed: {
  hotelData() {
   return this.$store.state.hotelData;
  },
  tabName(){
    switch (this.acIndex) {
    case 0:
     return "报警消息";
     break;
    case 1:
     return '服务消息';
     break;
    case 2:
     return "异常消息";
     break;
    case 3:
     return '设备离线';
     break;

    default:
     return "报警消息";
     break;
   }
  },
  tablist() {
   switch (this.acIndex) {
    case 0:
     return this.msgList;
     break;
    case 1:
     return this.serviceList;
     break;
    case 2:
     return this.eqList;
     break;
    case 3:
     return this.JkList;
     break;
    default:
     break;
   }
  },
  execlTab() {
   switch (this.acIndex) {
    case 0:
     return this.msgTabs;
     break;
    case 1:
     return this.serverTabs;
     break;
    case 2:
     return this.abnormalTabs;
     break;
    case 3:
     return this.faultTabs;
     break;

    default:
     return this.msgTabs;
     break;
   }
  },
  typeLists() {
   let obj = typeLists;
   obj.orderTypeList = this.$store.state.orderTypeList;
   obj.ckList = this.$store.state.ckList;
   obj.orderStatusList = this.$store.state.orderStatusList;
   return obj;
  },
 },
 created() {
   this.getToday()
  // console.log(this.user, "--------------");
 },
 mounted() {
  this.getMsgList();
  this.getTab234();
  this.initList();
  this.initMin();
 },
 methods: {
   getToday(){
    this.today=[getDay(-7),getDay()]
   
   },
  initList() {
   this.myInterval = window.setInterval(() => {
     this.getMsgList();
   }, 5000);
  },
  getMsgList() {
   getHotelWarning(this.hotelData.id).then((res) => {
    this.msgList = res.data.data;
    this.checkWarning();
   });
  },
  initMin() {
   this.MinInterval = window.setInterval(() => {
     this.getTab234();
   },  60 * 1000);
  },
  getTab234() {
   this.getService();
   this.getEqList();
  //  this.getJkList();
  },
  getEqList() {
   warningList(1, 10, {
    hotelId: this.hotelData.id,
    endDate: `${this.today[1]} ${this.defaultTime[1]}`,
    startDate: `${this.today[0]} ${this.defaultTime[0]}`,
    source:'workbench',
    // startDate: "2020-01-15 00:00:00",
    // endDate: "2022-01-14 23:59:59",
   }).then((res) => {
    if (res.data.code == "000000") {
     this.eqList = res.data.data.rows;
     this.eqCount = res.data.data.total;
    }
   });
  },
  getJkList() {

   let listQuery = {
    // endTime: "2022-01-14 23:59:59",
    endTime: `${this.today[1]} ${this.defaultTime[1]}`,
    hotelId: this.hotelData.id,
    pageNum: 1,
    pageSize: 10,
    startTime: `${this.today[0]} ${this.defaultTime[0]}`,
    // startTime: "2020-01-14 00:00:00",
   };
   getDeviceFaultAlarmList(listQuery).then((res) => {
    if (res.data.code == "000000") {
     this.JkList = res.data.data.rows;
     this.JkCount = res.data.data.total;
    }
   });
  },
  getService() {
   // getServiceOrderCount(this.hotelData.id).then(res=>{
   //   console.log(res);
   //   if(res.data.code=='000000'){
   //     this.serviceOrderCount=res.data.data
   //   }
   // })
   getServiceOrders(1, 10, { hotelId: this.hotelData.id }).then((res) => {
    if (res.data.code == "000000") {
     this.serviceList = res.data.data.records;
     this.serviceOrderCount = res.data.data.total;
    }
   });
  },

  checkWarning() {
   let SOSList = [],
    offLineList = [];
   if (this.msgList.length) {
    this.msgList.forEach((item) => {
      // || item.status == "门牌离线"
     if (item.status == "SOS" ) {
      SOSList.push(item);
     }
     if (item.status == "门牌离线" ) {
      offLineList.push(item);
     }
    });
   }
   let sos = sessionStorage.getItem("warningSos");
   if (sos) {
    let oldSos = JSON.parse(sessionStorage.getItem("warningSos"));
    if (oldSos.length !== SOSList.length) {
     this.tip = true;
    } else {
     SOSList.forEach((item) => {
      oldSos.forEach((item1, index) => {
       if (item.roomNumber === item1.roomNumber) {
        oldSos.splice(index, 1);
       }
      });
     });
    }
    if (oldSos.length) {
     this.tip = true;
    }
   }
   if (SOSList.length) {
    this.speckText(this.voiceTip);
   } else {
    this.stop();
   }
   this.SOSList = SOSList;
   this.offLineList=offLineList
   let arr=[]
   this.msgList=Array.from(new Set(arr.concat(SOSList,offLineList,this.msgList)))
   sessionStorage.setItem("warningSos", JSON.stringify(SOSList));
  },
  showLabelText(val, list) {
   return showLabel(val, list);
  },
  // 时长
  showLongTimes(start, end) {
   return getLongDateTime(start, end);
  },
  closeTips() {
   this.tip = false;
   let AidioBar = document.getElementById("audio");
  AidioBar.pause();
  },
  stop() {
   let AidioBar = document.getElementById("audio");
   // 已播放
   // this.tip = false
   if (this.tip && !AidioBar.paused) {
   AidioBar.pause();
   AidioBar.currentTime = 0;
   }
  },

  speckText(str) {
   let AidioBar = document.getElementById("audio");
  AidioBar.loop = true;
  AidioBar.defaultPlaybackRate = 1.5;
  AidioBar.load();
   // this.tip = true
   if (this.tip) {
    if (AidioBar.paused) {
     setTimeout(() => {
     AidioBar.play();
     }, 1500);
     //AidioBar.play();
    } else {
    AidioBar.pause();
    }
   }
  },
  changeIndex(i) {
   this.acIndex = i;
  },

  // 打开详情
  openMsg() {
   this.$refs.msgBar.openDialog();
  },
  openOrders() {
   this.$refs.orders.openDialog();
  },
  openEqLog() {
   this.$refs.eqLog.openDialog();
  },
  openGzjk() {
   this.$refs.gzjk.openDialog();
  },
  openDetail() {
   switch (this.acIndex) {
    case 0:
     this.openMsg();
     break;
    case 1:
     this.openOrders();
     break;
    case 2:
     this.openEqLog();
     break;
    case 3:
     this.openGzjk();
     break;

    default:
     break;
   }
  },
 },
 destroyed() {
  this.myInterval && clearInterval(this.myInterval);
  this.MinInterval && clearInterval(this.MinInterval);
 },
};
</script>

<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");
.topTabs {
 height: 11vh;
 width: 100%;
}

.theTabRows {
 width: 100%;
 height: calc(100% - 13vh);
 .tabRowsBar {
  height: calc(100% - 4.5vh - 12px);
  overflow-y: auto;
 }
}
.tabRow {
 background-color: #fff !important;
}
.content2 {
 position: absolute;
 left: 50%;
 transform: translateX(-50%);
 bottom: 1vh;
 height: 3vh;
 line-height: 3vh;
}
.tabName {
 font-size: 2.2vh;
 color: #adadad;
 font-family: "宋体";
 font-family: "Microsoft YaHei-Bold, Microsoft YaHei";
 font-weight: bold;
 height: 3vh;
 line-height: 3vh;
 // font-weight: bold;
}
.activeType.tabBar {
 background-color: #f9ded7;
 transition: all 0.3s;
 .tabName {
  color: #ff0000;
 }
}
.TabNum {
 height: 3vh;
 line-height: 3vh;
 font-size: 2.2vh;
 color: #ff0000;
 font-weight: bold;
}
.tabShadow {
 box-shadow: 0px 2px 3px 1px rgba(41, 72, 152, 0.012037036940455437),
  0px 9px 7px 1px rgba(41, 72, 152, 0.01962962932884693),
  0px 22px 14px 1px rgba(41, 72, 152, 0.02500000037252903),
  0px 42px 28px 1px rgba(41, 72, 152, 0.03037036955356598),
  0px 71px 51px 1px rgba(41, 72, 152, 0.037962961941957474),
  0px 109px 87px 1px rgba(41, 72, 152, 0.05000000074505806);
 border-radius: 11px 11px 11px 11px;
}
.tabBar {
 margin: 0 0.4vw;
 flex: 1;
 text-align: center;
 padding: 1vh 0.8vw;
 height: 9vh;
 cursor: pointer;
 transition: all 0.3s;
 position: relative;
 .iconRing {
  position: absolute;
  top: 1vh;
  right: 0.4vw;
  font-size: 2.5vh;
  color: #f69e1e;
 }
}
.tabBar:nth-of-type(1) {
 margin-left: 0;
}
.tabBar:last-of-type {
 margin-right: 0;
}
.title {
 height: 4.5vh;
 line-height: 4.5vh;
}
</style>
