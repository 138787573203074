var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-box" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看明细",
            top: "5%",
            visible: _vm.show,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "title2 flexItem",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("img", {
                staticClass: "titleImg",
                attrs: {
                  src: require("../../../../../assets/benchCss/img/icon-list.png"),
                  alt: "",
                },
              }),
              _c("span", { staticClass: "ml" }, [
                _vm._v("\n    工作轨迹\n   "),
              ]),
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "managerTablist",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tablist,
                id: "tab",
                height: "400",
                stripe: "",
                "header-row-class-name": "tabHeader",
              },
            },
            _vm._l(_vm.execlTab, function (item, i) {
              return _c("el-table-column", {
                key: i,
                attrs: {
                  width: item.width,
                  prop: item.value,
                  label: item.label,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          item.value == "duration"
                            ? _c("div", [
                                _vm._v(
                                  "\n      " +
                                    _vm._s(
                                      _vm.showLongTimes(scope.row.duration)
                                    ) +
                                    "\n     "
                                ),
                              ])
                            : _c("div", [
                                _vm._v(
                                  "\n      " +
                                    _vm._s(scope.row[item.value]) +
                                    "\n     "
                                ),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
          _c(
            "div",
            {
              staticClass: "reset-button",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.exportExcel(0)
                    },
                  },
                },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }