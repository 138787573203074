<template>
 <div class="demo theBar borderBar">
  <div class="title flexSb">
   <div>
    入住情况
   </div>
   <div class="openIcon flexCenter">
    <!-- <el-dropdown class="user-name" trigger="click" @command="handleCommand">
     <span class="el-dropdown-link">
      {{type==1?'7天':"30天"}}
      <i class="el-icon-caret-bottom"></i>
     </span>
     <el-dropdown-menu slot="dropdown" style="padding:0">

      <el-dropdown-item divided command="1" style="border:0;margin-top:0">7天</el-dropdown-item>
      <el-dropdown-item divided command="2">30天</el-dropdown-item>
     </el-dropdown-menu>
    </el-dropdown> -->
    <i class="el-icon-search iconColor ml" @click="openData"></i>
   </div>
  </div>
  <div class="cbenchContent">
   <div id="checkInBar" class="chartBar"></div>
  </div>
  <checkData ref="checkData"></checkData>
 </div>
</template>
<script>
import echarts from "echarts";
import { GET_7DAYS_CHECK_IN } from "@/api";
import checkData from "./checkInDetail.vue";
export default {
 components: {
  checkData,
 },
 data() {
  return {
   type: 1,
   sevenPms: {
    dayRented: [11, 22, 53, 43, 56, 26, 71],
    // dayPMS: [],
    day: [],
   },
   list:[],
   hasFlag: true,
   showCharts: false,
  };
 },
 computed: {
  hotelData() {
   return this.$store.state.hotelData;
  },
 },
 mounted() {
  this.checkInBar = this.$echarts.init(document.getElementById("checkInBar"));
  window.addEventListener("resize", () => {
   this.checkInBar.resize();
  });
  this.getSevenCheckIN();
  this.daylineInter = null;
  this.daylineInter = setInterval(() => {
   this.getSevenCheckIN();
  }, 60 * 1000);
 },

 beforeDestroy() {
  clearInterval(this.daylineInter);
 },
 methods: {
  openData() {
   this.$refs.checkData.openDialog();
  },
  resize() {
   this.checkInBar.resize();
  },
  handleCommand(val) {
   if (this.type != val) {
    this.type = val;
   }
  },
  getDay(){
    return `${this.list[0].date} ~ ${this.list[this.list.length-1].date}`
  },
  getOptions(data) {
   return {
    title: {
     text: this.getDay(),
     right: "5%",
     top: "5%",
     textStyle: {
      fontWeight: 500,
      color: "#000", //字体颜色
      fontSize: 12, //字体大小
     },
    },
    tooltip: {
     trigger: "axis",
     axisPointer: {
      type: "none",
     },
     // formatter: "{b} : {c}%",
    },
    grid: {
     left: "5%",
     right: "8%",
     bottom: "5%",
     top: "10%",
     containLabel: true,
    },

    xAxis: {
     type: "category",
     inverse: true,
     axisLine: { show: false },
     axisTick: { show: false },
     splitLine: { show: false },
     //  axisLabel: {
     //   // show: true,
     //   textStyle: {},
     //  },
     data: data.day,
    },
    yAxis: {
     type: "value",
     axisLine: { show: false },
     axisTick: { show: false },
     splitLine: { show: false },
     axisLabel: { show: false },
    },

    series: {
     // name: "入住率",
     type: "bar",
     data: data.dayRented,
     itemStyle: {
      color: "#EBD8FA",
      barBorderRadius: 5,
      barBorderWidth: 12,
      emphasis: {
       color: "#9B3BE7",
      },
     },
    },
   };
  },
  // 获取七日内的pms 出租率情况
  async getSevenCheckIN(data = { hotelId: this.hotelData.id }) {
   try {
    this.hasFlag = true;

    let res = await GET_7DAYS_CHECK_IN(data);
  // console.log(res,'GET_7DAYS_CHECK_IN');
    if (!res.data.data || res.data.data.length == 0) {
     // this.hasFlag = false;
    } else {
     this.showCharts = true;
    //  "dayRented"
     [ "day","dayRented"].forEach((item) => {
      this.$set(this.sevenPms, item, []);
     });
      this.list=res.data.data
     this.list.forEach((item,index) => {
      this.sevenPms.dayRented.unshift(item.rate);
      // this.sevenPms.dayPMS.unshift(item.occupancyRate);
      let t = item.date?item.date.substring(5, item.date.length):'';
      this.sevenPms.day.unshift(t);
     });
    }
   } catch (error) {}

   if (this.hasFlag && this.showCharts) {
    this.initLine();
   }

   //  this.initLine();
   // this.sevenPms = res.data.data
  },
  initLine() {
   this.checkInBar.setOption(this.getOptions(this.sevenPms));
  },
 },
};
</script>
<style lang="scss" scoped>
@import url("../../../../../assets/benchCss/base.scss");
.demo {
 width: 100%;
 height: 100%;
 .cbenchContent {
  width: 100%;
  height: calc(100% - 5vh);
 }
}
</style>